import React, {Component} from 'react'

import {withRouter} from 'next/router'
import styled from 'styled-components'

import withAppContext from 'common/HOCs/withAppContext'
import withTranslation from 'common/HOCs/withTranslation'
import withUserContext from 'common/HOCs/withUserContext'
import {shouldShowDownloadAppBanner} from 'common/helpers'
import {resolveCurrentLanguage} from 'common/helpers/language'
import CPLink from 'components/CPLink'
import CPHeaderBar from 'components/promotions/CPHeaderBar'
import {DIGITALIZING_CULTURE_DE, DIGITALIZING_CULTURE_EN, HEADER} from 'consts.json'

import type {HeaderTypeAppearance} from '../../CPLayout/types'
import CPNavigationItems from '../CPNavigationItems'
import CPNavigationUserAction from '../CPNavigationUserAction'

import CPDownloadAppBanner from './CPDownloadAppBanner'
import CPToolbarCart from './CPToolbarCart'
import CPToolbarLang from './CPToolbarLang'
import CPToolbarLogo from './CPToolbarLogo'
import CPToolbarSearch from './CPToolbarSearch'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Nunito Sans;
  @media only screen and (min-width: 768px) {
    display: ${props => (props.showHeaderOnMobileOnly ? 'none' : 'flex')};
  }
`

const Header = styled.header`
  top: ${props =>
    props.showDownloadAppBanner
      ? props.showHeaderBar
        ? `${HEADER.DEFAULT_HEIGHT + HEADER.BAR_HEIGHT}px`
        : `${HEADER.DEFAULT_HEIGHT}px`
      : props.showHeaderBar
      ? `${HEADER.BAR_HEIGHT}px`
      : 0};
  left: 0;
  z-index: ${props => (props.isOpened ? '1111' : '1100')};
  position: fixed;
  box-sizing: border-box;
  display: flex;
  padding: 0 24px 0 24px;
  height: ${HEADER.DEFAULT_HEIGHT}px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  align-items: center;
  filter: ${props =>
    props.headerAppearance === HEADER.APPEARANCES.NORMAL
      ? 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16))'
      : ''};
  ${props =>
    props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? 'background-image: linear-gradient(180deg,rgba(0, 0, 0, 0.5), transparent);'
      : 'background-color: rgba(255, 255, 255, 1);'}

  @media only screen and (min-width: 768px) {
    padding: 0 32px 0 32px;
  }
`

const DropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
`
const HeaderBarItemDescText = styled.p`
  color: white;
  margin: 0 50px 0 0;
  font-weight: 100;
  font-size: 14px;
  padding-left: 10px;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
`
const HeaderLearnMoreLink = styled.a`
  text-decoration: none;
  color: #e14d6d;
  color: white;
  font-size: 12px;
  font-weight: 600;
`
const HeaderBarItemArrIcon = styled.div`
  background: url('/static/images/arrow-right-body.svg');
  height: 18px;
  width: 26px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-right: 10px;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
`

type Props = {
  appearance?: HeaderTypeAppearance,
  showLangDropdown: boolean,
  showHeaderOnMobileOnly?: boolean,
  showHeaderBar?: boolean,
  onDrawerToggleClicked?: Function
}

type State = {
  headerAppearance: HeaderTypeAppearance,
  currentLang: String
}

export class CPToolbar extends Component<Props, State> {
  mounted = false

  constructor(props) {
    super(props)

    this.state = {
      headerAppearance: HEADER.APPEARANCES.NORMAL,
      isOpened: false,
      currentLang: 'en'
    }
  }

  componentDidMount() {
    this.mounted = true
    if (this.props.appearance === HEADER.APPEARANCES.TRANSPARENT) {
      window.addEventListener('scroll', this.listenScrollEvent)
    }
    this.setState({
      headerAppearance: this.props.appearance,
      currentLang: resolveCurrentLanguage()
    })

    if (shouldShowDownloadAppBanner()) {
      this.props.appContext.setContextData({
        // TODO :: Task CPP-2610 - This is set to false in order to disable App banner
        showDownloadAppBanner: false
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.props.appearance === HEADER.APPEARANCES.TRANSPARENT) {
      window.removeEventListener('scroll', this.listenScrollEvent)
    }
  }

  onHideDownloadAppBanner = () => {
    window.sessionStorage.setItem('hideDownloadAppBanner', true)
    this.props.appContext.setContextData({
      showDownloadAppBanner: false
    })
  }

  listenScrollEvent = () => {
    if (this.mounted && this.props.appearance === HEADER.APPEARANCES.TRANSPARENT) {
      // eslint-disable-next-line no-magic-numbers
      if (window.innerHeight > 900) {
        // eslint-disable-next-line no-magic-numbers
        this.setToolbarVisibility((window.innerHeight / 100) * 23)
      } else {
        this.setToolbarVisibility(HEADER.HEADER_CHANGE_OFFSET)
      }
    }
  }

  setToolbarVisibility = offset => {
    if (this.mounted) {
      this.setState({
        headerAppearance: HEADER.APPEARANCES[window.scrollY >= offset ? 'NORMAL' : 'TRANSPARENT']
      })
    }
  }

  switchOpen = isOpened => {
    if (this.props.shouldSwitchOpen) {
      this.setState({isOpened})
    }
  }

  render() {
    if (this.state.headerAppearance === HEADER.APPEARANCES.HIDDEN) {
      return null
    }
    return (
      <HeaderWrapper
        className="header-wrapper"
        showHeaderOnMobileOnly={this.props.showHeaderOnMobileOnly}
      >
        <CPDownloadAppBanner
          showDownloadAppBanner={this.props.appContext.showDownloadAppBanner}
          onHideDownloadAppBanner={this.onHideDownloadAppBanner}
          deepLinkConfig={this.props.deepLinkConfig}
        />
        {this.props.showHeaderBar && (
          <CPHeaderBar
            color={'#C82853'}
            showDownloadAppBanner={this.props.appContext.showDownloadAppBanner}
          >
            <HeaderBarItemDescText>{this.props.t('DIGITALIZING_CULTURE')}</HeaderBarItemDescText>
            <CPLink
              staticFile
              href={`${
                this.state.currentLang === 'de' ? DIGITALIZING_CULTURE_DE : DIGITALIZING_CULTURE_EN
              }`}
            >
              <HeaderLearnMoreLink target="_blank">
                {this.props.t('INFO_AND_REGISTRATION')}
              </HeaderLearnMoreLink>
            </CPLink>
            <HeaderBarItemArrIcon />
          </CPHeaderBar>
        )}
        <Header
          {...this.state}
          isOpened={this.state.isOpened}
          showDownloadAppBanner={this.props.appContext.showDownloadAppBanner}
          hideHeaderBottomShadow={this.props.appContext.isStickyMenuActive}
          data-cy="cp-header"
          id="cp-header"
          showHeaderBar={this.props.showHeaderBar}
        >
          <CPToolbarLogo headerAppearance={this.state.headerAppearance} />
          <CPNavigationItems headerAppearance={this.state.headerAppearance} />
          <CPToolbarCart />
          {this.props.showLangDropdown && <CPToolbarLang />}
          <CPToolbarSearch
            switchOpen={this.switchOpen}
            showHeaderBar={this.props.showHeaderBar}
            headerAppearance={this.state.headerAppearance}
          />
          <CPNavigationUserAction
            isLoggedIn={this.props.user.isLogged}
            imagePath={this.props.user.image}
            firstName={this.props.user.firstName}
            lastName={this.props.user.lastName}
          />
        </Header>
      </HeaderWrapper>
    )
  }

  static defaultProps = {
    onDrawerToggleClicked: () => {},
    showLangDropdown: true,
    appearance: HEADER.APPEARANCES.NORMAL,
    showHeaderBar: false,
    shouldSwitchOpen: false
  }
}
export default withRouter(withUserContext(withAppContext(withTranslation(CPToolbar))))
