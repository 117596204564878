import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #4a5968;
  box-sizing: border-box;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px 8px;
  position: absolute;
  margin-top: 8px;
  right: 0;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`
