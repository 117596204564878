/**
 * Links to tour preview page and full tour page.
 *
 * @flow
 */

import CPLink from 'components/CPLink'
import React from 'react'
import {TOUR as TOUR_PREVIEW, TOUR_FULL} from 'routes.json'

type BaseLinkProps = {
  tourSlug: string,
  urlBase: TOUR_PREVIEW.link | TOUR_FULL.link
}

type LinkProps = {
  tourSlug: string
}

const TourPageCommonLink = ({tourSlug, urlBase, ...otherProps}: BaseLinkProps) => (
  <CPLink
    href={`${urlBase}/[slug]?slug=${tourSlug}`}
    as={`${urlBase}/${tourSlug}`}
    {...otherProps}
  />
)

export const CPTourPreviewPageLink = ({tourSlug, ...otherProps}: LinkProps) => (
  <TourPageCommonLink tourSlug={tourSlug} urlBase={TOUR_PREVIEW.link} {...otherProps} />
)

export const CPTourFullPageLink = ({tourSlug, ...otherProps}: LinkProps) => (
  <TourPageCommonLink tourSlug={tourSlug} urlBase={TOUR_FULL.link} {...otherProps} />
)
