import React from 'react'

import styled from 'styled-components'

import withTranslation from 'common/HOCs/withTranslation'
import CPLink from 'components/CPLink'
import {HEADER, COLOURS as colours} from 'consts.json'

import type {HeaderTypeAppearance} from '../../../CPLayout/types'

const ListItem = styled.li`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  vertical-align: middle;
  display: table-cell;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  padding: 8px 12px;
  height: inherit;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: ${props =>
    props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? 'white'
      : props.isActive && !props.isSignUp
      ? colours.activeLink
      : '#253044'};
  opacity: ${props =>
    props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? 'white'
      : props.isSignUp
      ? 'none'
      : props.isActive
      ? 'none'
      : '0.56'};

  &:before {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border: ${props =>
      props.withBox
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? '1px solid white'
          : '1px solid gray'
        : 'none'};
    background-color: ${props =>
      props.withBox && props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
        ? 'rgba(255, 255, 255, 0.1)'
        : 'transparent'};
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }

  :hover {
    span {
      color: ${props =>
        props.isActive
          ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
            ? 'white'
            : colours.activeLink
          : 'grey'};
    }

    &:before {
      border: ${props => (props.withBox ? '1px solid gray' : 'none')};
      background-color: 'transparent';
    }
  }
`

const CPLinkText = styled.span`
  transition: all 0.3s ease-in-out;
  font-size: 13px;

  @media screen and (min-width: 1200px) {
    font-size: 14px;
  }

  ${props =>
    props.separator &&
    `&:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 1px;
    background-color: #898989;
    transform: translateX(-50%) rotate(90deg);
    transition: all 0.3s ease-in-out;
  }`}
`

type Props = {
  key: String,
  link: String,
  text: String,
  isActive: Boolean,
  isSignUp: Boolean,
  openInNewTab: Boolean,
  headerAppearance?: HeaderTypeAppearance
}

const CPNavigationItem = (props: Props) => {
  const text = props.t(props.text)

  return (
    <ListItem authButton={props.authButton}>
      <CPLink href={props.link} openInNewTab={props.openInNewTab}>
        <LinkItem
          isActive={props.isActive}
          isSignUp={props.isSignUp}
          key={props.key}
          headerAppearance={props.headerAppearance}
          withBox={props.withBox}
        >
          <CPLinkText
            authButton={props.authButton}
            separator={props.separator}
            id={`toolbar-nav-item-${text}`}
          >
            {text}
          </CPLinkText>
        </LinkItem>
      </CPLink>
    </ListItem>
  )
}

CPNavigationItem.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL,
  withBox: false,
  authButton: false,
  separator: false,
  openInNewTab: false,
  isSignUp: false
}

export default withTranslation(CPNavigationItem)
