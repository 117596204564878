import {useState, useEffect} from 'react'

export default (passedValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(passedValue)

  useEffect(
    () => {
      // Set debouncedValue to passedValue after the specified delay.
      const timeoutRef = setTimeout(() => {
        setDebouncedValue(passedValue)
      }, delay)
      // Return a cleanup function.
      return () => {
        clearTimeout(timeoutRef)
      }
    },
    // Only recall effect if passedValue changes.
    [passedValue]
  )

  return debouncedValue
}
