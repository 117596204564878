/**
 * clientSideOnly
 * Higher-order-like component that renders only on client side
 * @flow
 * TODO: use 'hoist-react-staticsr' or in some other way hoist static members.
 * For example if you wrap next page that uses getInitialProps it won't work,
 * because getInitialProps will not propagate to HOCed component.
 */

import {Component} from 'react'
import {DotsLoader} from 'components/common/CPLoaders'

export default function clientSideOnly(WrappedComponent: Component, setLoader = false): Component {
  return class WrapperComponent extends Component {
    state = {allowRender: false}
    componentDidMount() {
      if (process.browser) {
        this.setState({allowRender: true})
      }
    }

    render() {
      return this.state.allowRender ? (
        <WrappedComponent {...this.props} />
      ) : setLoader ? (
        <DotsLoader />
      ) : null
    }
  }
}
