import React from 'react'
import {ActivatorIcon} from './styled'
import {COLOR_VARIANTS} from './index'

export function getActivatorComponent(
  PassedActivatorComponent: React.ComponentType,
  colorVariant: String
): React.ComponentType {
  const activatorIconPath =
    colorVariant === COLOR_VARIANTS.WHITE
      ? '/static/images/more-vertical-black.svg'
      : '/static/images/more-vertical-white.svg'

  let ActivatorComponent = <ActivatorIcon src={activatorIconPath} />

  const passedActivatorComponentExist = !!PassedActivatorComponent
  if (passedActivatorComponentExist) {
    ActivatorComponent = PassedActivatorComponent
  }

  return ActivatorComponent
}
