import {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {initInitialsBackgroundColor} from 'lib/slices/user'

const IMAGE_PATH_FOR_NOLOGIN_USER = '/static/images/user-action-non-logged.png'

function useLogic({isLoggedIn, profileImagePath, profileFirstName, profileLastName}) {
  const imagePath = isLoggedIn ? profileImagePath : IMAGE_PATH_FOR_NOLOGIN_USER
  const isDefaultProfileImage = imagePath && imagePath.includes('defaults')
  const profileText =
    imagePath && !isDefaultProfileImage
      ? ''
      : `${profileFirstName.charAt(0)}${profileLastName.charAt(0)}`

  // TODO: In case we switch to login via redux toolkit, remove this dispatch,
  // and process login action in user reducer to set colour.
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initInitialsBackgroundColor())
  }, [])

  const initialsBackgroundColor = useSelector(state => state.user?.initialsBackgroundColor)

  return {imagePath, isDefaultProfileImage, profileText, initialsBackgroundColor}
}

export default useLogic
