import React from 'react'
import styled from 'styled-components'
import Item from './item'
import ItemLink from './itemLink'

const Wrapper = styled.div`
  overflow-y: auto;
  padding: 17px 24px;
  flex: 1;

  > * {
    margin: 15px 0;
  }

  @media only screen and (min-width: 768px) {
    padding: 17px 144px;
  }
`

const List = ({items}) => (
  <Wrapper>
    {items.map(item => (
      <ItemLink {...item} key={item.id + '_' + item.entityType}>
        <Item {...item} />
      </ItemLink>
    ))}
  </Wrapper>
)

export default List
