import styled from 'styled-components'

export const MenuWrapper = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.24);
  position: fixed;
  bottom: 0;
  height: auto;
  transform: ${props => (props.showMenu ? 'translateY(-74px)' : 'translateY(100%)')};
  transition: all ease 0.45s;
  z-index: 1200;
  border-radius: ${props => (props.showOtherMenuItems ? 0 : '24px 24px 0 0')};
  padding-top: ${props => (props.showOtherMenuItems ? '80px' : '30px')};
  padding-bottom: ${props => (props.showMenu ? '0' : '')};
  overflow: ${props => (props.showOtherMenuItems ? 'scroll' : 'hidden')};
`

export const OtherItemsWrapper = styled.div`
  width: 100%;
  display: ${props => (props.showOtherMenuItems ? 'flex' : 'none')};
  flex-direction: column;
  gap: 16px;
`

export const CloseIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 24px;
  right: 24px;
  display: ${props => (props.showOtherMenuItems ? 'grid' : 'none')};
  place-items: center;
  opacity: 1;
  transition: all ease 5s;
`

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
`

export const Item = styled.a`
  display: flex;
  flex: row;
  align-items: center;
  justify-content: start;
  width: 95%;
  gap: 16px;
  text-decoration: none;
`

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  background: #f0f2f4;
  border-radius: 50%;
`

export const Text = styled.div`
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #000000;
`
