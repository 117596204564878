import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.Node
}

const Wrapper = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  top: 100%;
  right: ${props => (props.right ? '0' : 'auto')};
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;

  &:before,
  &:after {
    content: '';
    display: block;
    border-style: solid;
    border-width: 0 12px 7px 12px;
    border-color: transparent;
    position: absolute;
    left: ${props => (props.center ? '50%' : props.right ? 'auto' : '20px')};
    right: ${props => (props.right ? '20px' : 'auto')};
    transform: ${props => (props.center ? 'translateX(-50%)' : '')};
  }

  &:before {
    top: -7px;
    border-bottom-color: rgba(0, 0, 0, 0.025);
  }

  &:after {
    top: -7px;
    border-bottom-color: #fff;
  }
`
const DropdownContent = (props: Props) => {
  const {children, ...dropdownContentProps} = props
  return (
    <Wrapper className="dropdown-content-wrapper" {...dropdownContentProps}>
      {children}
    </Wrapper>
  )
}

export default DropdownContent
