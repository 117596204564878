/**
 * CPPlaceLink component. Wrapps next/link to provide easy to use and correct link to place.
 *
 * @flow
 */

import React from 'react'
import CPLink from 'components/CPLink'
import {getPlaceURL} from 'common/helpers'

function getPlaceHref({seoURL, citySlug, countrySlug, id}) {
  if (seoURL && citySlug && countrySlug) {
    return `/place/[country]/[city]/[slug]?country=${countrySlug}&city=${citySlug}&slug=${seoURL}`
  } else return '/place?id=' + id
}

/*
 *
 */
type Props = {
  id?: String,
  seoURL?: String,
  citySlug?: String,
  countrySlug?: String,
  src?: String
}

const CPPlaceLink = (props: Props) => {
  const url = getPlaceURL(props)
  const href = getPlaceHref(props)
  return (
    <CPLink as={url} href={href}>
      {props.children}
    </CPLink>
  )
}

export default CPPlaceLink
