import React, {useEffect, useState} from 'react'

import {useRouter} from 'next/router'
import {useTranslation} from 'react-i18next'
import {BECOME_A_HOST, FOOTER_MENU, HOME, OTHER_FOOTER_MENU_ITEMS} from 'routes.json'

import ExploreIcon from 'assets/icons/footer_explore_icon.svg'
import HomeIcon from 'assets/icons/footer_home_icon.svg'
import HostIcon from 'assets/icons/footer_host_icon.svg'
import useDetectKeyboardOpen from 'common/hooks/useDetectKeyboardOpen'
import CPLink from 'components/CPLink'

import FooterMenu from './components/FooterMenu'
import FooterNavigationButton from './components/FooterNavigationButton'

import * as S from './styled'

const CPNavigationFooter = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showOtherMenuItems, setShowOtherMenuItems] = useState(false)
  const [isExploreButtonActive, setIsExploreButtonActive] = useState(false)
  const router = useRouter()
  const {t} = useTranslation()
  const isKeyboardOpen = useDetectKeyboardOpen()

  useEffect(() => {
    const fullMenu = [...FOOTER_MENU, ...OTHER_FOOTER_MENU_ITEMS]
    setIsExploreButtonActive(fullMenu.some(item => router.pathname === item.link))
  }, [])

  return (
    <>
      <S.FooterNavigationWrapper
        isDisplayed={isKeyboardOpen}
        className={'footer-navigation-wrapper'}
      >
        <CPLink href={HOME.link}>
          <S.NavButtonWrapper>
            <FooterNavigationButton
              text={t('HOME')}
              iconComponent={HomeIcon}
              isActive={router.pathname === HOME.link}
              onClick={() => {
                setShowOtherMenuItems(false)
                setShowMenu(false)
              }}
            />
          </S.NavButtonWrapper>
        </CPLink>
        <FooterNavigationButton
          text={t('EXPLORE')}
          iconComponent={ExploreIcon}
          isActive={isExploreButtonActive}
          onClick={() => {
            setShowMenu(prev => !prev)
            setShowOtherMenuItems(false)
          }}
        />
        <CPLink href={BECOME_A_HOST.link}>
          <S.NavButtonWrapper>
            <FooterNavigationButton
              text={t('HOST')}
              iconComponent={HostIcon}
              isActive={router.pathname === BECOME_A_HOST.link}
              onClick={() => {
                setShowOtherMenuItems(false)
                setShowMenu(false)
              }}
            />
          </S.NavButtonWrapper>
        </CPLink>
      </S.FooterNavigationWrapper>
      <FooterMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showOtherMenuItems={showOtherMenuItems}
        setShowOtherMenuItems={setShowOtherMenuItems}
      />
    </>
  )
}

export default CPNavigationFooter
