import styled from 'styled-components'

export const Wrapper = styled.div`
  min-width: 40px;
  margin-right: 8px;
`

export const MenuButton = styled.div`
  background: url('/static/images/globe_dark.svg') no-repeat center/cover;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const MenuButtonWrapper = styled.div`
  cursor: pointer;
  border: ${props => (props.isDropdownActive ? '1px solid #14181C' : '1px solid #D2D9DF')};
  border-radius: 50%;
  padding: 8px;
`
