import React, {Component} from 'react'
import {CPCartContext} from 'common/context/CPCart/CPCartProvider'

const withCartContext = ComponentToExtend => {
  return class WrapperComponent extends Component {
    static getInitialProps = ComponentToExtend.getInitialProps
    render() {
      return (
        <CPCartContext.Consumer>
          {value => <ComponentToExtend {...this.props} shoppingCart={value.shoppingCart} />}
        </CPCartContext.Consumer>
      )
    }
  }
}

export default withCartContext
