import React from 'react'
import * as S from './styled'
import * as T from './types'

const FooterNavigationButton = ({
  text = '',
  onClick = () => {},
  iconComponent = null,
  isActive,
  className
}: T.Props) => {
  const Icon = iconComponent

  return (
    <S.Wrapper onClick={onClick} isActive={isActive} className={className}>
      {Icon ? <Icon /> : null}
      {text ? <S.Text isActive={isActive}>{text}</S.Text> : null}
    </S.Wrapper>
  )
}

export default FooterNavigationButton
