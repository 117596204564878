import React, {useMemo, useState} from 'react'

import withTranslation from 'common/HOCs/withTranslation'
import {changeLanguageHelper} from 'common/helpers'
import {languageMapHeader} from 'components/CPLayout/common/CPFooter/footerMappings'
import {Dropdown} from 'components/common/Dropdowns'
import {COLOR_VARIANTS} from 'components/common/Dropdowns/components/Dropdown'

import * as S from './styled'

const CPToolbarLang = ({i18n}) => {
  const [isActive, setIsActive] = useState(false)
  const langItems = useMemo(() => {
    return languageMapHeader.map(lang => ({
      text: lang.label,
      onClick: () => changeLanguageHelper(lang.value)
    }))
  }, [i18n.language])

  return (
    <S.Wrapper>
      <Dropdown
        onStateChange={setIsActive}
        Activator={
          <S.MenuButtonWrapper isDropdownActive={isActive}>
            <S.MenuButton />
          </S.MenuButtonWrapper>
        }
        items={langItems}
        colorVariant={COLOR_VARIANTS.WHITE}
        closeOnItemClick
      />
    </S.Wrapper>
  )
}
export default withTranslation(CPToolbarLang)
