import React from 'react'

import * as S from './styled'
import * as T from './types'

function Item({
  text,
  onClick = () => {},
  toggleIsActive,
  disabled,
  colorVariant,
  closeOnItemClick,
  isSelected = null,
  href = ''
}: T.Props): React.ComponentType {
  const onItemClick = () => {
    if (!disabled) {
      if (closeOnItemClick) {
        toggleIsActive()
      }
      onClick()
    }
  }

  return (
    <>
      {href !== '' ? (
        <S.LinkWrapper
          onClick={onItemClick}
          isSelected={isSelected}
          isDisabled={disabled}
          colorVariant={colorVariant}
          href={href}
          target={'_blank'}
        >
          <S.Text className="cp-dropdown-item">{text}</S.Text>
        </S.LinkWrapper>
      ) : (
        <S.Wrapper
          onClick={onItemClick}
          isSelected={isSelected}
          isDisabled={disabled}
          colorVariant={colorVariant}
        >
          <S.Text className="cp-dropdown-item">{text}</S.Text>
        </S.Wrapper>
      )}
    </>
  )
}

export default Item
