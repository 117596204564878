import CPLink from 'components/CPLink'
import React from 'react'

const CPDestinationLink = ({slug, ...otherProps}) => (
  <CPLink
    href={`/destination/[destination]?destination=${slug}`}
    as={`/destination/${slug}`}
    {...otherProps}
  />
)

export default CPDestinationLink
