import React, {cloneElement, Component} from 'react'
import {findDOMNode} from 'react-dom'
import styled from 'styled-components'
import DropdownTrigger from './dropdownTrigger.js'
import DropdownContent from './dropdownContent.js'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

type Props = {
  disabled: Boolean,
  active: Boolean,
  onHide: Function,
  onShow: Function,
  children: React.Node,
  removeElement: Function
}

class Dropdown extends Component<Props> {
  componentDidMount() {
    window.addEventListener('click', this.onWindowClick)
    window.addEventListener('touchstart', this.onWindowClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick)
    window.removeEventListener('touchstart', this.onWindowClick)
  }

  constructor(props) {
    super(props)

    this.state = {
      active: false
    }
  }

  isActive() {
    return typeof this.props.active === 'boolean' ? this.props.active : this.state.active
  }

  hide() {
    this.setState(
      {
        active: false
      },
      () => {
        if (this.props.onHide) {
          this.props.onHide()
        }
      }
    )
  }

  show() {
    this.setState(
      {
        active: true
      },
      () => {
        if (this.props.onShow) {
          this.props.onShow()
        }
      }
    )
  }

  onWindowClick = event => {
    const dropdownElement = findDOMNode(this)
    const clickedElement = findDOMNode(event.target)
    if (
      clickedElement !== dropdownElement &&
      !dropdownElement.contains(clickedElement) &&
      this.isActive()
    ) {
      this.hide()
    }
  }

  onToggleClick = event => {
    event.preventDefault()
    if (this.isActive()) {
      this.hide()
    } else {
      this.show()
    }
  }

  render() {
    const {children, disabled, removeElement} = this.props
    const active = this.isActive()
    // stick callback on trigger element
    const boundChildren = React.Children.map(children, child => {
      if (child.type === DropdownTrigger) {
        const originalOnClick = child.props.onClick
        child = cloneElement(child, {
          active: active,
          ref: 'trigger',
          onClick: event => {
            if (!disabled) {
              this.onToggleClick(event)
              if (originalOnClick) {
                originalOnClick.apply(child, arguments)
              }
            }
          }
        })
      } else if (child.type === DropdownContent && removeElement && !active) {
        child = null
      } else {
        child = React.cloneElement(child, {active: active})
      }
      return child
    })
    const cleanProps = {...this.props}
    delete cleanProps.active
    delete cleanProps.onShow
    delete cleanProps.onHide
    delete cleanProps.removeElement

    return (
      <Wrapper className="dropdown-wrapper" {...cleanProps} active={active}>
        {boundChildren}
      </Wrapper>
    )
  }
}

export {DropdownTrigger, DropdownContent}
export default Dropdown
