import React, {useState, useEffect, useRef} from 'react'

import * as S from './styled'
import icons from 'assets/icons'
import {withRouter} from 'next/router'
import {findDOMNode} from 'react-dom'
import CPToolbarCartPopup from './CPToolbarCartPopup'
import CPLink from '../../../CPLink'
import {CHECKOUT} from 'routes.json'
import withCartContext from 'common/HOCs/withCartContext'
import clientSideOnly from 'common/HOCs/clientSideOnly'

type Props = {
  shoppingCart: Object
}

const CPToolbarCart = ({shoppingCart, router}): Props => {
  const {cart, cartInitalized} = shoppingCart
  const {itemsCount} = cart
  const [prevItemsCount, setPrevItemsCount] = useState(itemsCount)
  const [prevCartReady, setCartReady] = useState(cartInitalized)
  const [showCartNotification, setShowCartNotification] = useState(false)
  const [showCartItemsOnHover, setShowCartItemsOnHover] = useState(false)

  const myRef = useRef()
  const isCheckout = router.asPath.includes('/checkout')
  useEffect(() => {
    document.addEventListener('mousedown', onWindowClick)
    return () => document.removeEventListener('mousedown', onWindowClick)
  })

  useEffect(() => {
    if (cartInitalized && !prevCartReady) {
      setCartReady(cartInitalized)
    }
  }, [cartInitalized])

  useEffect(() => {
    if (cartInitalized && prevCartReady && !isCheckout && itemsCount !== prevItemsCount) {
      setShowCartNotification(true)
      setPrevItemsCount(itemsCount)
      setCartReady(cartInitalized)
    }
  }, [itemsCount, prevItemsCount])

  const onWindowClick = event => {
    const clickedElement = findDOMNode(event.target)
    if (
      (showCartNotification || showCartItemsOnHover) &&
      clickedElement !== myRef.current &&
      !myRef?.current?.contains(clickedElement)
    ) {
      setShowCartNotification(false)
      setShowCartItemsOnHover(false)
    }
  }

  const onClickCartIcon = () => {
    return isCheckout ? null : (setShowCartItemsOnHover(true), setShowCartNotification(false))
  }

  const cartIcon = (
    <S.CartIconWrapper
      onMouseEnter={() => onClickCartIcon()}
      isCheckout={isCheckout}
      data-cy="cp-toolbar-cart"
    >
      <icons.profile.orders.cart />
      {shoppingCart && cart && itemsCount !== 0 ? (
        <S.CartNotification data-cy="cp-toolbar-cart-notification">
          <S.CartNotificationNumber
            length={itemsCount.toString().length}
            data-cy="cp-toolbar-cart-notification-number"
          >
            {itemsCount}
          </S.CartNotificationNumber>
        </S.CartNotification>
      ) : null}
    </S.CartIconWrapper>
  )

  const shoduldLinkToCheckout =
    isCheckout || itemsCount === 0 ? (
      <>{cartIcon}</>
    ) : (
      <CPLink href={CHECKOUT.link} as={CHECKOUT.link}>
        {cartIcon}
      </CPLink>
    )

  return itemsCount && itemsCount > 0 ? (
    <S.ToolbarCartWrapper itemsCount={itemsCount}>
      {shoduldLinkToCheckout}
      {showCartNotification ? (
        <div ref={myRef}>
          <CPToolbarCartPopup productAdded />
        </div>
      ) : null}
      {showCartItemsOnHover ? (
        <div ref={myRef}>
          <CPToolbarCartPopup shoppingCart={shoppingCart} />
        </div>
      ) : null}
    </S.ToolbarCartWrapper>
  ) : null
}

export default clientSideOnly(withRouter(withCartContext(CPToolbarCart)))
