/**
 * @flow
 */
import React from 'react'
import {fetchThumbnailsMemoizer} from 'common/helpers'

type Props = {
  getPlacesFromData: Function,
  query: any,
  children: Function
}

type State = {
  loading: boolean,
  error: boolean,
  data: Array
}

export class PlacesWithThumbnailsQuery extends React.Component<Props, State> {
  state = {
    loading: true,
    error: false,
    data: []
  }

  mounted = false

  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    this.mounted = true
    this.fetchData()
  }

  async componentDidUpdate(prevProps) {
    // Query should be run only when variables are updated.
    if (this.props.variables !== prevProps.variables && this.mounted) {
      await this.fetchData()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  fetchThumbnailsMemoized = fetchThumbnailsMemoizer()

  fetchData = async () => {
    const {props} = this
    this.setState({loading: true, error: false})
    try {
      const queryResult = await props.query(props.variables)
      const data = props.getPlacesFromData(queryResult)
      const withThumbnails = await this.fetchThumbnailsMemoized(data)
      if (this.mounted) {
        this.setState({loading: false, error: false, data: withThumbnails})
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({loading: false, error: true, data: []})
      }
    }
  }

  render() {
    const {loading, error, data} = this.state
    return this.props.children({loading, error, data})
  }
}

export default PlacesWithThumbnailsQuery
