import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => (props.isOpened ? '1px solid #4A5968' : '1px solid #d2d9df')};
  box-sizing: border-box;
  border-radius: 44px;
  padding: 8px;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuIcon = styled.img`
  width: 20px;
  height: 20px;
`
