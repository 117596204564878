import React, {useState} from 'react'

import {useRouter} from 'next/router'

import withCartContext from 'common/HOCs/withCartContext'
import withTranslation from 'common/HOCs/withTranslation'
import {Dropdown} from 'components/common/Dropdowns'

import Activator from './components/Activator'
import {getNavSettingsAndComponents} from './functions'

import * as S from './styled'

function CPNavigationUserAction({
  t,
  isLoggedIn = false,
  imagePath = '',
  firstName = '',
  lastName = '',
  shoppingCart
}) {
  const [isOpened, setIsOpened] = useState(false)
  const router = useRouter()

  const settings = getNavSettingsAndComponents({
    t,
    router,
    isLoggedIn,
    imagePath,
    firstName,
    lastName,
    clearCart: shoppingCart.clearCart
  })

  return (
    <S.Wrapper>
      <Dropdown
        onStateChange={setIsOpened}
        Activator={<Activator Icon={settings.Icon} isOpened={isOpened} />}
        items={settings.dropdownItems}
        closeOnItemClick={true}
      />
    </S.Wrapper>
  )
}

export default withCartContext(withTranslation(CPNavigationUserAction))
