import React, {useMemo} from 'react'
import styled from 'styled-components'
import PlacesWithThumbnailsQuery from '../../common/PlacesWithThumbnailsQuery'
import {resolveCurrentLanguage} from 'common/helpers'
import withTranslation from 'common/HOCs/withTranslation'
import {DotsLoader} from '../../common/CPLoaders/index'
import {getSearchEntities} from 'services/searchService'
import List from './list'

const NoResultsWrapper = styled.div`
  padding: 32px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    padding: 32px 144px;
    justify-content: flex-start;
  }
`

const NoResultsImg = styled.img`
  margin-right: 16px;
`

const NoResultsText = styled.p``

const getPlacesFromSearchData = data => (data && data.results) || []

const NoResults = ({t}) => (
  <NoResultsWrapper>
    <NoResultsImg src="/static/images/smiley-with-glasses.png" width={32} height={32} />
    <NoResultsText>{t('SEARCH_NO_RESULTS_TEXT')}</NoResultsText>
  </NoResultsWrapper>
)

const SearchResults = ({searchTerm, t}) => {
  const currLang = resolveCurrentLanguage()
  const variables = useMemo(
    () => ({
      phrase: searchTerm,
      lang: currLang
    }),
    [searchTerm]
  )

  return (
    <PlacesWithThumbnailsQuery
      query={getSearchEntities}
      variables={variables}
      getPlacesFromData={getPlacesFromSearchData}
    >
      {({loading, data}) => {
        if (loading) {
          return <DotsLoader />
        }

        if (data.length > 0) {
          return <List items={data} />
        }

        return <NoResults t={t} />
      }}
    </PlacesWithThumbnailsQuery>
  )
}

export default withTranslation(SearchResults)
