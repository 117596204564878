import styled from 'styled-components'

export const Wrapper = styled.div`
  cursor: pointer;
  border: ${props => (props.isActive ? '1px solid #14181C' : '1px solid #D2D9DF')};
  border-radius: 50%;
  padding: 8px 8px 4px 8px;
  margin-right: 8px;
`

export const SearchExit = styled.div`
  background: url('/static/images/ic_close.svg') no-repeat center;
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
`

export const searchStyle = {
  cursor: 'pointer'
}
