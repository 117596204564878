import React, {useEffect, useRef, useState} from 'react'

import styled from 'styled-components'

import withAppContext from 'common/HOCs/withAppContext'
import withTranslation from 'common/HOCs/withTranslation'
import useDebounce from 'common/hooks/useDebounce'
import {HEADER} from 'consts.json'

import SearchResults from './components/searchResults'

const DEBOUNCE_TIME = 500

const Wrapper = styled.div`
  display: ${props => (props.visible === true ? 'block' : 'none')};
  font-family: 'Nunito Sans Bold', sans-serif;
`

const Backdrop = styled.div`
  position: fixed;
  top: ${props => props.topOffset};
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
`

const TransparentBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1400;
  background-color: transparent;
  display: block;
  cursor: default;
`

const SearchWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topOffset};
  left: 0;
  width: 100%;
  overflow: hidden;
  max-height: calc(88vh - ${props => props.topOffset});
  z-index: 1401;
  background: white;
`

const SearchInput = styled.input`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 1.36;
  border: none;
  height: 64px;
  padding-left: 24px;
  display: inline-block;
  background-color: #61b7cb;
  color: white;
  border-radius: 0;
  -webkit-appearance: none;

  ::placeholder {
    color: white;
    opacity: 0.8;
  }

  :focus {
    outline: none;
  }

  ::-ms-clear {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    padding-left: 144px;
    height: 128px;
    font-size: 22px;
  }
`

const SearchHeader = styled.div`
  display: flex;
  position: relative;
`

const InputBoxWrapper = styled.div`
  width: 100%;
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  input[type='search'] {
    -webkit-appearance: none;
  }

  input {
    width: 100%;
  }
`

const calculateTopOffset = (showAppDownloadBanner, showHeaderBar) => {
  let topOffset = HEADER.DEFAULT_HEIGHT
  if (showAppDownloadBanner) {
    topOffset += HEADER.DOWNLOAD_APP_BANNER_HEIGHT
  }
  if (showHeaderBar) {
    topOffset += HEADER.BAR_HEIGHT
  }
  return topOffset + 'px'
}

const CPSearch = ({appContext, showHeaderBar, onClose, t}) => {
  const inputRef = useRef()
  const [searchTerm, setSearchTerm] = useState('')
  const [rawSearchTerm, setRawSearchTerm] = useState(searchTerm)
  const debouncedSearchTerm = useDebounce(rawSearchTerm, DEBOUNCE_TIME)
  const topOffset = calculateTopOffset(appContext.showDownloadAppBanner, showHeaderBar)
  const showSearchResults = (debouncedSearchTerm && debouncedSearchTerm.length > 1) || false
  const isVisible = appContext.showSearch

  useEffect(() => {
    if (inputRef.current) {
      if (isVisible) {
        // Bring input field in focus when search is opened.
        inputRef.current.focus()
      } else {
        // Reset input field and search terms when search is closed.
        setSearchTerm('')
        setRawSearchTerm('')
        inputRef.current.value = ''
      }
    }
  }, [isVisible])

  // Update search term.
  useEffect(() => {
    setSearchTerm(debouncedSearchTerm)
  }, [debouncedSearchTerm])

  /**
   * Handles change of search input.
   * It escapes forbidden characters from raw string.
   *
   * @param event {Object} Input element onchange event.
   */
  const handleInputChange = event => {
    const escapedValue = event.target.value.replace(/[<>()|'"]/g, '')
    setRawSearchTerm(escapedValue)
  }

  /**
   * Handles close operation.
   */
  const onCloseHandler = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Wrapper visible={isVisible} data-cy="cp-search">
      <SearchWrapper topOffset={topOffset}>
        <SearchHeader>
          <InputBoxWrapper>
            <SearchInput
              type="search"
              autoComplete="off"
              autocomplete="chrome-off"
              placeholder={t('SEARCH_EXPLORE_PLACEHOLDER')}
              onChange={handleInputChange}
              ref={inputRef}
              data-cy="cp-search-input"
              defaultValue={searchTerm}
            />
          </InputBoxWrapper>
        </SearchHeader>
        {showSearchResults ? <SearchResults searchTerm={debouncedSearchTerm} /> : null}
      </SearchWrapper>
      <Backdrop topOffset={topOffset} />
      <TransparentBackdrop onClick={onCloseHandler} />
    </Wrapper>
  )
}

export default withAppContext(withTranslation(CPSearch))
