import React from 'react'

import {SETTINGS} from 'routes.json'

import ProfileImageWithInitials from 'components/common/ProfileImageWithInitials'
import {logout} from 'services/auth'

import {ProfileImageWrapper} from './styled'

export function getNavSettingsAndComponents({
  t,
  router,
  isLoggedIn = false,
  imagePath = '',
  firstName = '',
  lastName = '',
  clearCart = () => {}
}) {
  let dropdownItems = [
    {
      text: t('SIGN_IN'),
      onClick: () => router.push('/login')
    },
    {
      text: t('SIGN_UP'),
      onClick: () => router.push('/signup')
    }
  ]

  if (isLoggedIn) {
    dropdownItems = [
      {
        text: t('PROFILE'),
        onClick: () => router.push('/profile')
      },
      {
        text: t('SETTINGS'),
        onClick: () => router.push(SETTINGS.link)
      },
      {
        text: t('LOG_OUT'),
        onClick: () => {
          logout()
          clearCart()
        }
      }
    ]
  }

  const Icon = (
    <ProfileImageWrapper>
      <ProfileImageWithInitials
        isLoggedIn={isLoggedIn}
        profileImagePath={imagePath}
        profileFirstName={firstName}
        profileLastName={lastName}
      />
    </ProfileImageWrapper>
  )

  return {
    Icon,
    dropdownItems
  }
}
