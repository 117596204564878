import React from 'react'

import styled from 'styled-components'

import withTranslation from 'common/HOCs/withTranslation'
import theme from 'common/helpers/theme'
import {SEARCH_ENTITY_TYPES} from 'consts.json'

const resolveTagColor = entityType => {
  switch (entityType) {
    case SEARCH_ENTITY_TYPES.DESTINATION:
      return theme.colors.yellow
    case SEARCH_ENTITY_TYPES.ATTRACTION:
      return theme.colors.primary2
    case SEARCH_ENTITY_TYPES.GUIDE:
      return theme.colors.blue
    case SEARCH_ENTITY_TYPES.EXPERIENCE:
      return theme.colors.primary4
    default:
      return '#D7D7D7'
  }
}

const resolveTagLabel = (entityType, t) => {
  switch (entityType) {
    case SEARCH_ENTITY_TYPES.DESTINATION:
      return t('SEARCH_RESULT_TYPE_DESTINATION_LABEL')
    case SEARCH_ENTITY_TYPES.ATTRACTION:
      return t('SEARCH_RESULT_TYPE_ATTRACTION_LABEL')
    case SEARCH_ENTITY_TYPES.GUIDE:
      return t('SEARCH_RESULT_TYPE_GUIDE_LABEL')
    case SEARCH_ENTITY_TYPES.PLACE:
      return t('SEARCH_RESULT_TYPE_PLACE_LABEL')
    case SEARCH_ENTITY_TYPES.EXPERIENCE:
      return t('SEARCH_RESULT_TYPE_EXPERIENCE_LABEL')
    default:
      return ''
  }
}

const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
`

const ImageWrapper = styled.div`
  margin-right: 26px;
`

const Image = styled.img`
  border-radius: 8px;
  width: 72px;
  object-fit: cover;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #253044;
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`

const Description = styled.p`
  margin: 0;
  opacity: 0.32;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
  margin-bottom: 9px;
`

const Tag = styled.div`
  color: ${props => (props.type === SEARCH_ENTITY_TYPES.PLACE ? '#253044' : 'white')};
  background-color: ${props => resolveTagColor(props.type)};
  border-radius: 8px;
  width: fit-content;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
`

function Item(props) {
  const {t, entityType} = props
  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={props.thumbnail} width={72} height={72} />
      </ImageWrapper>
      <TitleWrapper>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <Tag type={entityType}>{resolveTagLabel(entityType, t).toUpperCase()}</Tag>
      </TitleWrapper>
    </Wrapper>
  )
}

export default withTranslation(Item)
