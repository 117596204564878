import React from 'react'

import CPLink from 'components/CPLink'

const CPExperienceLink = ({slug, ...otherProps}) => {
  return (
    <CPLink
      replace={true}
      shallow={false}
      href={`/experience/[slug]?slug=${slug}`}
      as={`/experience/${slug}`}
      {...otherProps}
    />
  )
}

export default CPExperienceLink
