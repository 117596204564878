import styled from 'styled-components'

import {NAV_FOOTER} from 'consts.json'

export const FooterNavigationWrapper = styled.div`
  width: 100%;
  display: ${props => (props.isDisplayed ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  font-family: Nunito Sans;
  font-style: normal;
  background-color: #fff;
  height: ${NAV_FOOTER.DEFAULT_HEIGHT}px;
  gap: 30px;
  z-index: 1210;
  border-top: solid 1px rgba(0, 0, 0, 0.04);

  ${props => props.theme.mediaQuery.lg} {
    display: none;
  }
`

export const NavButtonWrapper = styled.div``
