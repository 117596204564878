/**
 * @flow
 */

import {Component} from 'react'
import {CPUserContext} from 'common/context/CPUser/CPUserProvider'

const withUserContext = ComponentToExtend => {
  return class WrapperComponent extends Component {
    static getInitialProps = ComponentToExtend.getInitialProps
    render() {
      return (
        <CPUserContext.Consumer>
          {value => <ComponentToExtend {...this.props} user={value.user} />}
        </CPUserContext.Consumer>
      )
    }
  }
}

export default withUserContext
