import React from 'react'
import icons from 'assets/icons'
import styled from 'styled-components'
import {HOME} from 'routes.json'
import {HEADER} from 'consts.json'
import useResize from 'common/hooks/useResize'
import type {HeaderTypeAppearance} from '../../../CPLayout/types'
import CPLink from 'components/CPLink'
import {BASE_CONF} from 'components/common/CPTheme'

const Wrapper = styled.div`
  height: 28px;
  margin: auto auto auto 1.5%;

  @media only screen and (min-width: 768px) {
    height: 28px;
    margin: auto auto auto 1%;
  }

  @media only screen and (min-width: 1024px) {
    margin: 0 auto 0 0;
    height: 28px;
  }
`

const LogoWrapper = styled.a`
  text-decoration: none;

  :hover,
  :active {
    outline: none;
  }
`

const logoStyle = {
  height: '28px'
}

type Props = {
  headerAppearance: HeaderTypeAppearance
}

const CPToolbarLogo = (props: Props) => {
  const [width] = useResize()
  const headerIconStyle = HEADER.APPEARANCE_ICON_STYLES[props.headerAppearance]
  const Logo = width >= BASE_CONF.breakpoints.xl ? icons.logo[headerIconStyle] : icons.logo.globe

  return (
    <Wrapper>
      <CPLink href={HOME.link}>
        <LogoWrapper id="cp_logo">
          <Logo style={logoStyle} id={`cp_toolbar_logo-${headerIconStyle}`} />
        </LogoWrapper>
      </CPLink>
    </Wrapper>
  )
}

CPToolbarLogo.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL
}

export default CPToolbarLogo
