import styled, {css} from 'styled-components'

import {COLOR_VARIANTS} from '../Dropdown'

export const wrapperStyle = css`
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  padding: 4px 8px;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  user-select: ${props => (props.isDisabled ? 'none' : 'auto')};
  border-radius: 7px;
  background: ${props =>
    props.isSelected
      ? props => (props.colorVariant === COLOR_VARIANTS.WHITE ? '#f0f2f4' : '#2e363e')
      : 'transparent'};

  :hover {
    background: ${props => (props.colorVariant === COLOR_VARIANTS.WHITE ? '#f0f2f4' : '#2e363e')};
    border-radius: 7px;
  }

  > span {
    color: ${props =>
      props.isDisabled
        ? '#97a6b5'
        : props.colorVariant === COLOR_VARIANTS.WHITE
        ? '#14181C'
        : '#FFFFFF'};
  }
`

export const Wrapper = styled.div`
  ${wrapperStyle}
`

export const LinkWrapper = styled.a`
  ${wrapperStyle}
  display: inline-block;
  text-decoration: none;
`

export const Text = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  white-space: nowrap;
`
