import React from 'react'
import styled from 'styled-components'
import CPLink from 'components/CPLink'
import {CHECKOUT} from 'routes.json'
import {getCurrencySymbol} from 'common/helpers'
import withCartContext from 'common/HOCs/withCartContext'
import withTranslation from 'common/HOCs/withTranslation'
import {BASE_CONF} from 'components/common/CPTheme'

const CartDetailsPanelWrapper = styled.div`
  position: absolute;
  top: 38px;
  right: -175px;
  display: flex;
  flex-direction: column;
  z-index: 1100;
  transition: ease-in;

  @media screen and ${BASE_CONF.screens.md} {
    right: -5px;
  }
`

const CartDetailsPanelArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
  align-self: flex-end;
  margin-right: 210px;

  @media screen and ${BASE_CONF.screens.md} {
    margin-right: 34px;
  }
`

const CartDetailsPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 6px;
  width: 272px;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    width: 350px;
  }
`

const CartDetailsPanelText = styled.p`
  margin: 0;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #f2f2f2;
  flex: 1;
`

const CartDetailsPanleLink = styled.a`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: unset;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const ItemAddedToCartSection = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
`

const CartProductsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CartListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`

const CartListProduct = styled.div`
  display: flex;
  flex-direction: row;
`

const CartListDetailsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  flex: 1;
`

const CartListPriceSection = styled.div`
  display: flex;
  flex-direction: row;

  p:first-child {
    margin-right: 5px;
  }
`

const ItemImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  height: 60px;
  width: 60px;
`

const CartPriceText = styled(CartDetailsPanelText)`
  flex: none;
  font-size: 12px;
  font-weight: lighter;
`

const ViewCartBtn = styled.a`
  border: 1px solid #f2f2f2;
  width: 110px;
  height: 34px;
  background-color: transparent;
  color: white;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  flex: 2.5;
  cursor: pointer;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
`

const ViewCartSection = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
`

const TotalPriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const TotalPrice = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: white;
`

type Props = {
  shoppingCart: Object,
  productAdded?: boolean,
  t: Function
}

const CPToolbarCartPopup = ({shoppingCart, productAdded, t}): Props => {
  const {cart} = shoppingCart
  const {itemsCount, order} = cart
  const {items, currency, total} = order

  const reduceCartDescLength = product => {
    if (product.itemType === 'MediaBundle') {
      // eslint-disable-next-line no-magic-numbers
      return product.title.length > 65 ? `${product.title.substr(0, 63)}...` : product.title
    } else {
      // eslint-disable-next-line no-magic-numbers
      return product.title.length > 48 ? `${product.title.substr(0, 45)}...` : product.title
    }
  }

  const noProductsMessage = () => {
    return (
      <ItemAddedToCartSection data-cy="cp-toolbar-cart-no-product-message">
        <CartDetailsPanelText>{t('NO_PRODUCTS_IN_THE_CART')}</CartDetailsPanelText>
      </ItemAddedToCartSection>
    )
  }

  const addedProductMessage = () => {
    return (
      <ItemAddedToCartSection data-cy="cp-toolbar-cart-added-product-message">
        <CartDetailsPanelText>{t('ADDED_TO_CART')}</CartDetailsPanelText>
        <CPLink href={CHECKOUT.link} as={CHECKOUT.link}>
          <CartDetailsPanleLink data-cy="cp-toolbar-view-cart-link">
            {t('VIEW_CART')}
          </CartDetailsPanleLink>
        </CPLink>
      </ItemAddedToCartSection>
    )
  }

  const productList = () => {
    const list = items.map(product => {
      return (
        <CartListWrapper key={product.itemId}>
          <CartListProduct data-cy="cp-toolbar-cart-list-items">
            <ItemImage image={product.image} data-cy="cp-toolbar-cart-list-items-image" />
            <CartListDetailsTextWrapper data-cy="cp-toolbar-cart-list-items-details">
              <CartDetailsPanelText>{reduceCartDescLength(product)}</CartDetailsPanelText>
              {product.type === 'Voucher' ? (
                <CartPriceText>{t('ENTRANCE_VOUCHER')}</CartPriceText>
              ) : null}
              <CartListPriceSection>
                <CartPriceText>{product.quantity}x</CartPriceText>
                <CartPriceText>{getCurrencySymbol(product.currency)}</CartPriceText>
                <CartPriceText>{product.price.toFixed(2)}</CartPriceText>
              </CartListPriceSection>
            </CartListDetailsTextWrapper>
          </CartListProduct>
        </CartListWrapper>
      )
    })
    return (
      <CartProductsPanelWrapper>
        {list}
        <ViewCartSection>
          <CPLink href={CHECKOUT.link} as={CHECKOUT.link}>
            <ViewCartBtn data-cy="cp-toolbar-view-cart-link">{t('VIEW_CART')}</ViewCartBtn>
          </CPLink>
          <TotalPriceWrapper>
            <TotalPrice>{getCurrencySymbol(currency)}</TotalPrice>
            <TotalPrice>{total.toFixed(2)}</TotalPrice>
          </TotalPriceWrapper>
        </ViewCartSection>
      </CartProductsPanelWrapper>
    )
  }

  return (
    <CartDetailsPanelWrapper data-cy="cp-toolbar-cart-notification-popup">
      <CartDetailsPanelArrow />
      <CartDetailsPanelBody>
        {productAdded
          ? addedProductMessage()
          : itemsCount === 0
          ? noProductsMessage()
          : productList()}
      </CartDetailsPanelBody>
    </CartDetailsPanelWrapper>
  )
}

CPToolbarCartPopup.defaultProps = {
  productAdded: false
}

export default withCartContext(withTranslation(CPToolbarCartPopup))
