import React from 'react'

import useLogic from './useLogic'

import {ProfileImage} from './styled'

export default function ProfileImageWithInitials({
  isLoggedIn,
  profileImagePath,
  profileFirstName,
  profileLastName,
  className
}) {
  const {imagePath, isDefaultProfileImage, profileText, initialsBackgroundColor} = useLogic({
    isLoggedIn,
    profileImagePath,
    profileFirstName,
    profileLastName
  })

  return (
    <ProfileImage
      imagePath={imagePath}
      isDefaultImg={isDefaultProfileImage}
      initialsBackgroundColor={initialsBackgroundColor}
      className={className}
    >
      {profileText}
    </ProfileImage>
  )
}
