import React, {Component} from 'react'
import styled from 'styled-components'
import {BASE_CONF} from 'components/common/CPTheme'

type Props = {
  children: React.Node,
  headerAppearance: Boolean,
  renderIcon: Boolean,
  showIcon: React.Node,
  hideIcon: React.Node
}

const Link = styled.div`
  display: flex;
  height: 100%;
  text-decoration: none;
  outline: none;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
`

const DropdownIcon = styled.div`
  margin: 0;
  padding: 0;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  background-image: ${props =>
    props.active
      ? props.showIcon
        ? `url(${props.showIcon})`
        : 'url(/static/images/ic_caret_up.svg)'
      : props.hideIcon
      ? `url(${props.hideIcon})`
      : 'url(/static/images/ic_caret_down.svg)'};
  background-position: center;
  background-repeat: no-repeat;
  display: ${props =>
    props.renderIcon && props.visibleOn.includes('xxs') ? 'inline-block' : 'none'};

  @media screen and ${BASE_CONF.screens.xs} {
    display: ${props =>
      props.renderIcon && (props.visibleOn.includes('xxs') || props.visibleOn.includes('xs'))
        ? 'inline-block'
        : 'none'};
  }

  @media screen and ${BASE_CONF.screens.sm} {
    display: ${props =>
      props.renderIcon &&
      (props.visibleOn.includes('xxs') ||
        props.visibleOn.includes('xs') ||
        props.visibleOn.includes('sm'))
        ? 'inline-block'
        : 'none'};
  }

  @media screen and ${BASE_CONF.screens.md} {
    display: ${props =>
      props.renderIcon &&
      (props.visibleOn.includes('xxs') ||
        props.visibleOn.includes('xs') ||
        props.visibleOn.includes('sm') ||
        props.visibleOn.includes('md'))
        ? 'inline-block'
        : 'none'};
  }

  @media screen and ${BASE_CONF.screens.lg} {
    display: ${props =>
      props.renderIcon &&
      (props.visibleOn.includes('xxs') ||
        props.visibleOn.includes('xs') ||
        props.visibleOn.includes('sm') ||
        props.visibleOn.includes('md') ||
        props.visibleOn.includes('lg'))
        ? 'inline-block'
        : 'none'};
  }

  @media screen and ${BASE_CONF.screens.xl} {
    display: ${props =>
      props.renderIcon &&
      (props.visibleOn.includes('xxs') ||
        props.visibleOn.includes('xs') ||
        props.visibleOn.includes('sm') ||
        props.visibleOn.includes('md') ||
        props.visibleOn.includes('lg') ||
        props.visibleOn.includes('xl'))
        ? 'inline-block'
        : 'none'};
  }
`

class DropdownTrigger extends Component<Props> {
  render() {
    const {children, ...dropdownTriggerProps} = this.props

    return (
      <Link {...dropdownTriggerProps} className="dropdown-trigger">
        {children}
        <DropdownIcon
          className="dropdown-icon"
          active={this.props.active}
          white={this.props.white}
          renderIcon={this.props.renderIcon}
          showIcon={this.props.showIcon}
          hideIcon={this.props.hideIcon}
          visibleOn={this.props.visibleOn}
        />
      </Link>
    )
  }
}

DropdownTrigger.defaultProps = {
  white: false,
  renderIcon: true,
  showIcon: undefined,
  hideIcon: undefined,
  visibleOn: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl']
}

export default DropdownTrigger
