/**
 * CPText component
 *
 * @flow
 */

import React from 'react'
import styled from 'styled-components'
import {Link as WrapperLink} from '../i18n'

const fullUrlRegExp = /^https?:\/\//
const isStaticResource = url => url.startsWith('/static/')

const AnchorTag = styled.a`
  text-decoration: ${props =>
    props.noUnderline && props.noUnderline !== undefined ? 'none' : 'underline'};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`

const CPClassicLink = props => {
  const {addATag, openInNewTab, children, href, noUnderline} = props
  const target = openInNewTab ? '_blank' : '_self'
  // Add anchor tag if needed.
  if (addATag) {
    return (
      <AnchorTag isDisabled={props.isDisabled} target={target} noUnderline={noUnderline}>
        {children}
      </AnchorTag>
    )
  }
  // Else just return children with href injected.
  return React.Children.map(props.children, child => React.cloneElement(child, {href, target}))
}

const CPNextLink = props => {
  const {addATag, openInNewTab, children, noUnderline, ...otherProps} = props

  // Add anchor tag if needed.
  const content = addATag ? (
    <AnchorTag
      isDisabled={props.isDisabled}
      target={openInNewTab ? '_blank' : '_self'}
      noUnderline={noUnderline}
    >
      {children}
    </AnchorTag>
  ) : (
    children
  )
  return (
    <WrapperLink passHref {...otherProps}>
      {content}
    </WrapperLink>
  )
}

type Props = {
  href: String,
  as?: String,
  prefetch?: Boolean,
  addATag?: Boolean,
  openInNewTab?: Boolean
}

const CPLink = (props: Props) => {
  const {href} = props
  if (!href) {
    return null
  }

  // Use Next's link for routes from /pages directory,
  // and anchor tag for full urls and static assets.
  const Link = fullUrlRegExp.test(href) || isStaticResource(href) ? CPClassicLink : CPNextLink
  return <Link {...props} />
}

export default CPLink
