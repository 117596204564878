import {BECOME_A_HOST, DRAWER_MENU, FOOTER_MENU, SUPPORT} from 'routes.json'

import {PRIVACY_POLICY_URL, TERMS_AND_PRIVACY_URL} from 'consts.json'

export const footerLinksMap = {
  PLATFORM: FOOTER_MENU.map(item => ({
    title: item.text,
    url: item.link
  })),
  INFO: [
    {
      title: BECOME_A_HOST.text,
      url: BECOME_A_HOST.link
    },
    {
      title: SUPPORT.text,
      url: SUPPORT.link
    },
    {
      title: 'ABOUT_US',
      url: '/about'
    },
    {
      title: DRAWER_MENU.CONTACT.text,
      url: DRAWER_MENU.CONTACT.link
    }
  ],
  LEGAL: [
    {
      title: 'TERMS_AND_CONDITIONS',
      url: TERMS_AND_PRIVACY_URL,
      target: '_blank'
    },
    {
      title: 'PRIVACY_POLICY',
      url: PRIVACY_POLICY_URL,
      target: '_blank'
    }
  ]
}

export const socialLinks = {
  instagram: 'https://www.instagram.com/cultural_places/',
  x: 'https://x.com/Cultural_Places',
  facebook: 'https://www.facebook.com/Cultural.Places/',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.cpapp',
  appStore: 'https://itunes.apple.com/us/app/cultural-places/id1234962719?mt=8'
}

export const languageMapHeader = [
  // {value: 'id', label: 'Bahasa Indonesia'},
  {value: 'de', label: 'Deutsch'},
  {value: 'en', label: 'English'}
  // {value: 'hr', label: 'Hrvatski'},
  // {value: 'it', label: 'Italiano'},
  // {value: 'sl', label: 'Slovenščina'},
  // {value: 'sr', label: 'Српски'},
  // {value: 'sh', label: 'Srpski'},
  // {value: 'zh', label: '中文'}
]

export const LINK_TYPE = {
  SOCIAL: 'social',
  STORE: 'store'
}
