import React from 'react'

import styled from 'styled-components'

import CPAttractionLink from 'components/CPAttractionLink'
import CPDestinationLink from 'components/CPDestinationLink'
import CPExperienceLink from 'components/CPExperienceLink'
import CPLink from 'components/CPLink'
import CPPlaceLink from 'components/CPPlace/CPPlaceLink'
import {CPTourPreviewPageLink} from 'components/CPTourLink'
import {SEARCH_ENTITY_TYPES} from 'consts.json'

const StyledLink = styled.a`
  text-decoration: none;
`

const resolveLinkComponent = ({entityType, slug, id, citySlug, countrySlug}) => {
  switch (entityType) {
    case SEARCH_ENTITY_TYPES.DESTINATION:
      return props => <CPDestinationLink {...props} slug={slug} />
    case SEARCH_ENTITY_TYPES.ATTRACTION:
      return props => <CPAttractionLink {...props} slug={slug} />
    case SEARCH_ENTITY_TYPES.GUIDE:
      return props => <CPTourPreviewPageLink {...props} tourSlug={slug} />
    case SEARCH_ENTITY_TYPES.PLACE:
      return props => (
        <CPPlaceLink
          {...props}
          seoURL={slug}
          citySlug={citySlug}
          countrySlug={countrySlug}
          id={id}
        />
      )
    case SEARCH_ENTITY_TYPES.EXPERIENCE:
      return props => <CPExperienceLink {...props} slug={slug} />
    default:
      // In highly unlikely case of missing or unknown type, return link to home page.
      return props => <CPLink {...props} href={'/'} />
  }
}

const ItemLink = props => {
  const LinkComponent = resolveLinkComponent(props)
  return (
    <div>
      <LinkComponent>
        <StyledLink>{props.children}</StyledLink>
      </LinkComponent>
    </div>
  )
}

export default ItemLink
