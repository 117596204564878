import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;

  .cp-dropdown-items-wrapper {
    width: 171px;
  }
`

export const ProfileImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  div {
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
  }
`
