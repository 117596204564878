import React, {useEffect, useState, useRef} from 'react'
import * as S from './styled'
import icons from 'assets/icons'
import {HEADER} from 'consts.json'
import type {HeaderTypeAppearance} from '../../../CPLayout/types'
import CPSearch from '../../../CPSearch'
import {bodyOverflowVisibile} from 'common/helpers'
import withAppContext from 'common/HOCs/withAppContext'

type Props = {
  showHeaderBar: boolean,
  headerAppearance: HeaderTypeAppearance,
  switchOpen: Function,
  appContext: Object
}

const CPToolbarSearch = ({headerAppearance, switchOpen, showHeaderBar, appContext}: Props) => {
  const isVisible = appContext.showSearch

  // If component is unmounted, reset body overflow.
  useEffect(() => {
    return () => {
      bodyOverflowVisibile()
    }
  }, [])

  const headerIconStyle = HEADER.APPEARANCE_ICON_STYLES[headerAppearance]
  const SearchIcon = icons.search[headerIconStyle]

  const [isActive, setIsActive] = useState(false)

  const closeSearch = () => {
    appContext.setContextData({
      showSearch: false
    })
    setIsActive(false)
    switchOpen(false)
    bodyOverflowVisibile(isVisible)
  }

  const onTriggerClicked = () => {
    if (!isVisible) {
      setIsActive(prev => !prev)
      // Open search and close side menu.
      appContext.setContextData({
        showSearch: true,
        showSideDrawer: false
      })
      switchOpen(true)
    }
    // Update body overflow.
    bodyOverflowVisibile(isVisible)
  }

  const searchTrigger = <SearchIcon style={S.searchStyle} data-cy="cp-toolbar-search-icon" />

  return (
    <S.Wrapper
      data-cy="header-search-wrapper"
      headerAppearance={headerAppearance}
      onClick={() => onTriggerClicked()}
      isActive={isActive}
    >
      {searchTrigger}
      <CPSearch onClose={closeSearch} showHeaderBar={showHeaderBar} />
    </S.Wrapper>
  )
}

CPToolbarSearch.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL,
  showHeaderBar: false,
  switchOpen: () => {}
}

export default withAppContext(CPToolbarSearch)
