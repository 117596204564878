import React, {useState, useRef, useEffect} from 'react'

import Items from '../Items'
import {getActivatorComponent} from './functions'
import useOnClickOutside from 'common/hooks/useOnClickOutside'

import * as S from './styled'
import * as T from './types'

export const COLOR_VARIANTS = {
  WHITE: 'white',
  DARK: 'dark'
}

function Dropdown({
  Activator,
  items = [],
  colorVariant = COLOR_VARIANTS.WHITE,
  closeOnItemClick = false,
  onStateChange = () => {},
  className = ''
}: T.Props): React.ComponentType {
  const ref = useRef()
  const [isActive, setIsActive] = useState(false)
  const ActivatorComponent = getActivatorComponent(Activator, colorVariant)

  useEffect(() => {
    onStateChange(isActive)
  }, [isActive])

  useOnClickOutside(ref, () => {
    setIsActive(false)
  })

  const toggleActive = () => {
    setIsActive(prev => !prev)
  }

  return (
    <S.Wrapper className={`${className} cp-dropdown-wrapper`} ref={ref}>
      <S.DropdownContentWrapper isActive={isActive} colorVariant={colorVariant}>
        <S.ActivatorWrapper
          className="cp-dropdown-activator-wrapper"
          onClick={() => toggleActive()}
        >
          {ActivatorComponent}
        </S.ActivatorWrapper>
        {items.length > 0 && (
          <Items
            items={items}
            toggleIsActive={() => toggleActive()}
            closeOnItemClick={closeOnItemClick}
            colorVariant={colorVariant}
          />
        )}
      </S.DropdownContentWrapper>
    </S.Wrapper>
  )
}

export default Dropdown
