import React from 'react'

import * as S from './styled'

function Activator({Icon = null, isOpened = false}) {
  return (
    <S.Wrapper isOpened={isOpened}>
      <S.IconWrapper>{Icon}</S.IconWrapper>
      <S.MenuIcon src="/static/images/menu.svg" />
    </S.Wrapper>
  )
}

export default Activator
