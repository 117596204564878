import React from 'react'

import {withRouter} from 'next/router'
import {BECOME_A_HOST, HEADER_MENU, HEADER_MENU_DROPDOWN, HOST_HOME} from 'routes.json'

import withTranslation from 'common/HOCs/withTranslation'
import withUserContext from 'common/HOCs/withUserContext'
import {isRouteActive} from 'common/helpers'
import {DEFAULT_LANGUAGE, HEADER, HEADER_TYPES} from 'consts.json'

import type {HeaderTypeAppearance} from '../../CPLayout/types'

import CPNavigationItem from './CPNavigationItem'
import CPNavigationDropdownItem from './CPNavigationItem/dropdown'

import * as S from './styled'

type Props = {
  headerAppearance?: HeaderTypeAppearance,
  router: Object,
  i18n: Object,
  t: Function
}

function DotsTriggerComponent() {
  return (
    <S.DotsWrapper>
      <S.Dot />
      <S.Dot />
      <S.Dot />
    </S.DotsWrapper>
  )
}

export const CPNavigationItems = (props: Props) => {
  const hostLinkMapping = props.user.isHost ? HOST_HOME : BECOME_A_HOST
  const navItems = []

  for (const [key, route] of Object.entries(HEADER_MENU)) {
    navItems.push(
      route.type === HEADER_TYPES.DROPDOWN ? (
        <CPNavigationDropdownItem
          key={key}
          items={route.items}
          text={route.text}
          isActive={route.items.find(item => isRouteActive(props.router, props.i18n, item.link))}
          headerAppearance={props.headerAppearance}
        />
      ) : (
        <CPNavigationItem
          type={route.type}
          key={key}
          link={route.link}
          text={route.text}
          openInNewTab={route.newTab}
          isActive={isRouteActive(props.router, props.i18n, route.link)}
          headerAppearance={props.headerAppearance}
        />
      )
    )
  }

  return (
    <S.Wrapper>
      <S.NavWrapper>{navItems}</S.NavWrapper>
      <S.DropdownPartnersWrapper>
        {/* Commented out as part of CPP-3648 */}
        {/* <S.DotsDropdownWrapper>
          <CPNavigationDropdownItem
            items={HEADER_MENU_DROPDOWN}
            TriggerComponent={DotsTriggerComponent}
            headerAppearance={props.headerAppearance}
          />
        </S.DotsDropdownWrapper> */}
        <S.PartnerWrapper>
          <CPNavigationItem
            link={hostLinkMapping.link}
            text={hostLinkMapping.text}
            isActive={isRouteActive(props.router, props.i18n, hostLinkMapping.link)}
            headerAppearance={props.headerAppearance}
          />
        </S.PartnerWrapper>
      </S.DropdownPartnersWrapper>
    </S.Wrapper>
  )
}

CPNavigationItems.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL,
  t: e => e,
  i18n: {language: DEFAULT_LANGUAGE}
}

export default withUserContext(withTranslation(withRouter(CPNavigationItems)))
