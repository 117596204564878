/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'
import Ratings from 'react-ratings-declarative'
import withTranslation from 'common/HOCs/withTranslation'
import icons from 'assets/icons'
import {resolveDeepLinkUrl} from 'common/helpers'
import CPLink from 'components/CPLink'
import {HEADER} from 'consts.json'

const Notice = styled.div`
  top: 0;
  left: 0;
  z-index: 1054;
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 8px;
  height: ${props => (props.height ? `${props.height}px` : `${HEADER.DEFAULT_HEIGHT}px`)};
  width: 100%;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;

  color: ${props => (props.appearance === 'light' ? '#000000' : '#ffffff')};
  background-color: ${props => (props.appearance === 'light' ? '#ffffff' : 'transparent')};
  background-image: ${props =>
    props.appearance === 'light' ? 'none' : 'linear-gradient(to right, #ef8148 0%, #e14d6d 100%)'};
`

const CloseContainer = styled.div`
  flex: 0 0 56px;

  @media screen and (min-width: 400px) {
    flex: 0 0 ${HEADER.DEFAULT_HEIGHT + HEADER.TOP_VALUE_ELEMENTS}px;
  }
`

const CloseButton = styled.a`
  width: 26px;
  height: 26px;
  background-color: transparent;
  margin: 0;
  padding: 12px 7px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  outline: none;
  display: inline-block;
  transition: all 0.5s;

  svg {
    width: 24px;
    height: 24px;
    transition: all 0.5s;
    path {
      stroke: ${props => (props.appearance === 'light' ? '#334b63' : '#ffffff')};
    }
  }

  &:hover,
  &:focus {
    border: none;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    svg {
      path {
        stroke: ${props => (props.appearance === 'light' ? '#000000' : '#cacaca')};
      }
    }
  }
`

const CloseIcon = icons.close.normal

const InfoContainer = styled.div`
  flex: 1 1 0;
`

const InfoText = styled.div`
  display: block;
  font-size: 12px;
  color: ${props => (props.appearance === 'light' ? '#334b63' : '#ffffff')};
`

const InfoStars = styled.div`
  display: block;
  margin: 2px 0 0 0;
  padding: 0;
  height: 20px;
  width: 120px;
`

const InstallContainer = styled.div`
  flex: 1 1 0;
  text-align: right;
`

const OpenLinkButton = styled.a`
  position: relative;
  white-space: nowrap;
  display: inline-block;
  padding: 10px 30px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  box-shadow: 2px 3px 12px -1px rgba(0, 0, 0, 0.25);

  color: ${props => (props.appearance === 'light' ? '#ffffff' : '#334b63')};
  background-color: ${props => (props.appearance === 'light' ? 'transparent' : '#ffffff')};
  background-image: ${props =>
    props.appearance === 'light'
      ? 'linear-gradient(to right,#ef8148 0%,#e14d6d 51%,#ef8148 100%)'
      : 'none'};
  background-size: 200% auto;

  &:hover,
  &:focus {
    border: none;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    color: ${props => (props.appearance === 'light' ? '#ffffff' : '#e14d6d')};
    background-position: right center;
  }

  @media screen and (min-width: 480px) {
    padding: 10px 50px;
  }
`

type Props = {
  appearance?: String,
  showDownloadAppBanner: Boolean,
  onHideDownloadAppBanner: Function,
  deepLinkConfig: Object
}

const CPDownloadAppBanner = (props: Props) => {
  const {appearance, showDownloadAppBanner, onHideDownloadAppBanner, deepLinkConfig} = props
  const {page, pageId} = deepLinkConfig
  if (!showDownloadAppBanner) {
    return null
  }

  const ratedColor = appearance === 'light' ? 'rgb(255, 180, 1)' : 'rgb(255, 255, 255)'
  const URL = resolveDeepLinkUrl({page, pageId})

  return (
    <Notice appearance={appearance} height={HEADER.DOWNLOAD_APP_BANNER_HEIGHT}>
      <CloseContainer appearance={appearance}>
        <CloseButton
          appearance={appearance}
          onClick={() => {
            if (onHideDownloadAppBanner) {
              onHideDownloadAppBanner()
            }
          }}
        >
          <CloseIcon />
        </CloseButton>
      </CloseContainer>
      <InfoContainer appearance={appearance}>
        <InfoText appearance={appearance}>{props.t('CULTURAL_PLACES_APP')}</InfoText>
        <InfoStars>
          <Ratings
            rating={4}
            widgetRatedColors={ratedColor}
            widgetEmptyColors={ratedColor}
            widgetHoverColors={ratedColor}
            numberOfStars={5}
            name="rating"
            widgetDimensions="15px"
            widgetSpacings="2px"
          >
            <Ratings.Widget
              widgetDimension="15px"
              svgIconViewBox="0 0 475.075 475.075"
              svgIconPath="M475.1,186.6c0-7-5.3-11.4-16-13.1l-143.3-20.8L251.5,22.7c-3.6-7.8-8.3-11.7-14-11.7c-5.7,0-10.4,3.9-14,11.7l-64.2,129.9L16,173.4c-10.7,1.7-16,6.1-16,13.1c0,4,2.4,8.6,7.1,13.7l103.9,101.1L86.5,444.1c-0.4,2.7-0.6,4.6-0.6,5.7c0,4,1,7.4,3,10.1c2,2.8,5,4.1,9,4.1c3.4,0,7.2-1.1,11.4-3.4l128.2-67.4l128.2,67.4c4,2.3,7.8,3.4,11.4,3.4c7.8,0,11.7-4.8,11.7-14.3c0-2.5-0.1-4.4-0.3-5.7L364,301.4l103.6-101.1C472.6,195.3,475.1,190.8,475.1,186.6z"
            />
            <Ratings.Widget
              widgetDimension="15px"
              svgIconViewBox="0 0 475.075 475.075"
              svgIconPath="M475.1,186.6c0-7-5.3-11.4-16-13.1l-143.3-20.8L251.5,22.7c-3.6-7.8-8.3-11.7-14-11.7c-5.7,0-10.4,3.9-14,11.7l-64.2,129.9L16,173.4c-10.7,1.7-16,6.1-16,13.1c0,4,2.4,8.6,7.1,13.7l103.9,101.1L86.5,444.1c-0.4,2.7-0.6,4.6-0.6,5.7c0,4,1,7.4,3,10.1c2,2.8,5,4.1,9,4.1c3.4,0,7.2-1.1,11.4-3.4l128.2-67.4l128.2,67.4c4,2.3,7.8,3.4,11.4,3.4c7.8,0,11.7-4.8,11.7-14.3c0-2.5-0.1-4.4-0.3-5.7L364,301.4l103.6-101.1C472.6,195.3,475.1,190.8,475.1,186.6z"
            />
            <Ratings.Widget
              widgetDimension="15px"
              svgIconViewBox="0 0 475.075 475.075"
              svgIconPath="M475.1,186.6c0-7-5.3-11.4-16-13.1l-143.3-20.8L251.5,22.7c-3.6-7.8-8.3-11.7-14-11.7c-5.7,0-10.4,3.9-14,11.7l-64.2,129.9L16,173.4c-10.7,1.7-16,6.1-16,13.1c0,4,2.4,8.6,7.1,13.7l103.9,101.1L86.5,444.1c-0.4,2.7-0.6,4.6-0.6,5.7c0,4,1,7.4,3,10.1c2,2.8,5,4.1,9,4.1c3.4,0,7.2-1.1,11.4-3.4l128.2-67.4l128.2,67.4c4,2.3,7.8,3.4,11.4,3.4c7.8,0,11.7-4.8,11.7-14.3c0-2.5-0.1-4.4-0.3-5.7L364,301.4l103.6-101.1C472.6,195.3,475.1,190.8,475.1,186.6z"
            />
            <Ratings.Widget
              widgetDimension="15px"
              svgIconViewBox="0 0 475.075 475.075"
              svgIconPath="M475.1,186.6c0-7-5.3-11.4-16-13.1l-143.3-20.8L251.5,22.7c-3.6-7.8-8.3-11.7-14-11.7c-5.7,0-10.4,3.9-14,11.7l-64.2,129.9L16,173.4c-10.7,1.7-16,6.1-16,13.1c0,4,2.4,8.6,7.1,13.7l103.9,101.1L86.5,444.1c-0.4,2.7-0.6,4.6-0.6,5.7c0,4,1,7.4,3,10.1c2,2.8,5,4.1,9,4.1c3.4,0,7.2-1.1,11.4-3.4l128.2-67.4l128.2,67.4c4,2.3,7.8,3.4,11.4,3.4c7.8,0,11.7-4.8,11.7-14.3c0-2.5-0.1-4.4-0.3-5.7L364,301.4l103.6-101.1C472.6,195.3,475.1,190.8,475.1,186.6z"
            />
            <Ratings.Widget
              widgetDimension="15px"
              svgIconViewBox="0 0 475.075 475.075"
              svgIconPath="M475.075,186.573c0-7.043-5.328-11.42-15.992-13.135L315.766,152.6L251.529,22.694c-3.614-7.804-8.281-11.704-13.99-11.704c-5.708,0-10.372,3.9-13.989,11.704L159.31,152.6L15.986,173.438C5.33,175.153,0,179.53,0,186.573c0,3.999,2.38,8.567,7.139,13.706l103.924,101.068L86.51,444.096c-0.381,2.666-0.57,4.575-0.57,5.712c0,3.997,0.998,7.374,2.996,10.136c1.997,2.766,4.993,4.142,8.992,4.142c3.428,0,7.233-1.137,11.42-3.423l128.188-67.386l128.194,67.379c4,2.286,7.806,3.43,11.416,3.43c7.812,0,11.714-4.75,11.714-14.271c0-2.471-0.096-4.374-0.287-5.716l-24.551-142.744l103.634-101.069C472.604,195.33,475.075,190.76,475.075,186.573z M324.619,288.5l20.551,120.2l-107.634-56.821L129.614,408.7l20.843-120.2l-87.365-84.799l120.484-17.7l53.959-109.064l53.957,109.064l120.494,17.7L324.619,288.5z"
            />
          </Ratings>
        </InfoStars>
      </InfoContainer>
      <InstallContainer>
        <CPLink href={URL}>
          <OpenLinkButton appearance={appearance}>{props.t('OPEN_BUTTON_TEXT')}</OpenLinkButton>
        </CPLink>
      </InstallContainer>
    </Notice>
  )
}

CPDownloadAppBanner.defaultProps = {
  appearance: 'default', // Options 'light' || 'default'
  showDownloadAppBanner: false,
  onHideDownloadAppBanner: () => {},
  deepLinkConfig: {}
}

export default withTranslation(CPDownloadAppBanner)
