import styled from 'styled-components'
import {COLOR_VARIANTS} from './index'

export const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
`

export const DropdownContentWrapper = styled.div`
  position: relative;

  .cp-dropdown-items-wrapper {
    display: ${props => (props.isActive ? 'block' : 'none')};
    background: ${props => (props.colorVariant === COLOR_VARIANTS.WHITE ? '#ffffff' : '#20262D')};
    border: 1px solid #d2d9df;
  }
`

export const ActivatorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ActivatorIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
