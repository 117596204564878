import {Router} from 'i18n'
import cookie from 'js-cookie'
import nextCookie from 'next-cookies'
import {FORGOT_PASSWORD, LOG_IN, RESET_PASSWORD, SIGN_UP} from 'routes.json'

import {isEmptyObject, objectToQueryString, resolveCurrentLanguage} from 'common/helpers'
import {DEFAULT_LANGUAGE} from 'consts.json'

import {DotsLoader} from '../components/common/CPLoaders'

import {getSessionHistory} from './historyManager'

const fullUrlRegExp = /^https?:\/\//

const forbiddenPagesForLoggedUser = [
  FORGOT_PASSWORD.link,
  RESET_PASSWORD.link,
  LOG_IN.link,
  SIGN_UP.link
]

const defaultRedirectOptions = {
  shouldRedirect: true,
  redirectUrl: null
}

export const login = ({token, redirectOptions = defaultRedirectOptions}) => {
  cookie.set('token', token, {expires: 1})
  window.localStorage.setItem('login', Date.now())
  const {shouldRedirect = true, redirectUrl = null} = redirectOptions
  if (shouldRedirect) {
    if (redirectUrl) {
      if (fullUrlRegExp.test(redirectUrl)) {
        // Redirect user using window.location api.
        window.location = redirectUrl
      } else {
        // Use Next's router for client side transition.
        Router.push(redirectUrl, redirectUrl, {shallow: false})
      }
    } else {
      const {prevPath} = getSessionHistory()
      const currentLanguage = resolveCurrentLanguage()
      const path =
        !prevPath || forbiddenPagesForLoggedUser.some(page => prevPath.includes(page))
          ? currentLanguage === DEFAULT_LANGUAGE
            ? '/'
            : `/${currentLanguage}`
          : prevPath
      Router.push(path, path, {shallow: false})
    }
  }
}

export const auth = ctx => {
  const {token} = nextCookie(ctx)

  if (ctx.req && !token) {
    const query =
      'query' in ctx && !isEmptyObject(ctx.query) ? `${objectToQueryString(ctx.query)}` : ''
    // eslint-disable-next-line no-magic-numbers
    ctx.res.writeHead(302, {Location: `/login${query}`})
    ctx.res.end()
  }
  return token
}

export const logout = () => {
  cookie.remove('token')
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now())
  Router.push('/', '/', {shallow: false})
}

export const withAuthSync = WrappedComponent => {
  const Wrapper = props => {
    const {user} = props
    return user.isLogged ? <WrappedComponent {...props} /> : <DotsLoader />
  }

  Wrapper.getInitialProps = async ctx => {
    const token = auth(ctx)

    const componentProps =
      WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx))

    return {...componentProps, token}
  }

  return Wrapper
}
