import React from 'react'

import Item from '../Item'

import * as S from './styled'
import * as T from './types'

function Items({items, ...otherProps}: T.Props): React.ComponentType {
  return (
    <S.Wrapper className="cp-dropdown-items-wrapper">
      {items.map((item, index) => (
        <Item key={index} {...item} {...otherProps} />
      ))}
    </S.Wrapper>
  )
}

export default Items
