import {useEffect, useState} from 'react'

const DEFAULT_MIN_KEYBOARD_HEIGHT = 300

const useDetectKeyboardOpen = (minKeyboardHeight = DEFAULT_MIN_KEYBOARD_HEIGHT, defaultValue) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue)

  useEffect(() => {
    const listener = () => {
      const newIsKeybordOpen =
        window.visualViewport.height < window.screen.height - minKeyboardHeight

      if (isKeyboardOpen !== newIsKeybordOpen) {
        setIsKeyboardOpen(newIsKeybordOpen)
      }
    }
    window.visualViewport.addEventListener('resize', listener)
    return () => {
      window.visualViewport.removeEventListener('resize', listener)
    }
  }, [])

  return isKeyboardOpen
}

export default useDetectKeyboardOpen
