import styled from 'styled-components'

export const ProfileImage = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.imagePath && !props.isDefaultImg
      ? `url(${props.imagePath}) no-repeat center`
      : props.initialsBackgroundColor};
  background-size: cover;
  border-radius: 50%;
  color: #ffffff;
`
