import styled from 'styled-components'

export const ToolbarCartWrapper = styled.div`
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  position: relative;
`

export const CartIconWrapper = styled.div`
  margin-right: 8px;
  line-height: 10px;
  cursor: ${props => (props.isCheckout || props.itemsCount === 0 ? 'default' : 'pointer')};
  padding: 7px;
  border: ${props => (props.isCheckout ? '1px solid #14181C' : '1px solid #D2D9DF')};
  border-radius: 50%;
`
export const CartNotification = styled.div`
  height: 16px;
  width: 16px;
  background-color: #61b7cb;
  border-radius: 50%;
  position: absolute;
  margin-left: 15px;
  top: 0;
  margin-top: -5px;
`
export const CartNotificationNumber = styled.div`
  color: white;
  font-style: normal;
  font-size: ${props => (props.length > 1 ? '7px' : '10px')};
  font-weight: 700;
  text-align: center;
  line-height: normal;
  padding-top: 1px;
`
