import React from 'react'
import styled from 'styled-components'
import CPHomeSectionWrapper from '../CPHomeSectionWrapper'
const STATIC_SQUARE_LENGTH = 4
const STATIC_CIRCLE_LENGTH = 5

const CardWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const CardStaticWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
  display: inline-block;

  @media only screen and (min-width: 1024px) {
    width: 320px;
  }
`

const CardStaticCircleWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
  display: inline-block;

  @media only screen and (min-width: 768px) {
    width: 280px;
  }
`

const Card = styled.div`
  background-color: #fff;
  padding: 8px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
  height: 200px;
  overflow: hidden;

  &:only-child {
    margin-top: 0;
  }

  &:before {
    content: '';
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',
      endColorstr='#00ffffff',
      GradientType=1);
  }

  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`

const CardCircle = styled.div`
  position: relative;
  background-color: #fff;
  padding: 8px;
  border-radius: 2px;
  margin-bottom: 0;
  overflow: hidden;
  height: 200px;
  display: block;
  background-color: #ededed;

  &:only-child {
    margin-top: 0;
  }

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',
      endColorstr='#00ffffff',
      GradientType=1);
  }

  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`

const Circle = styled.div`
  height: 150px;
  position: absolute;
  top: -30px;
  left: -30px;
  border: solid 32px white;
  border-radius: 50%;
  background: transparent;
  width: 150px;
`

const CircleRightFill = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 155px;
  height: 200px;
  background: white;
`

const CircleBottomFill = styled.div`
  position: absolute;
  top: 155px;
  right: 0;
  left: 0;
  height: 30px;
  background: white;
`

const CircleBottomRightFill = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 15px;
  width: 140px;
  background: white;
`

const CircleBottomSeparator = styled.div`
  position: absolute;
  top: 197px;
  right: 0;
  left: 0;
  height: 7px;
  background: white;
`

const Wrapper = styled.div`
  text-align: center;
  align-content: center;
  height: 270px;
  padding-left: 8px;
  @media only screen and (min-width: 1024px) {
    padding: 0;
  }
`

export const CardListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;

  @media only screen and (min-width: 1024px) {
    width: 920px;
  }

  @media only screen and (min-width: 1340px) {
    width: 1200px;
  }

  @media only screen and (min-width: 1440px) {
    width: 1255px;
  }
`

export const CardListStaticWrapper = styled.div`
  overflow: hidden;
  background-color: transparent;
  white-space: nowrap;
`

const renderItems = itemsToShow => {
  let items = []
  for (let i = 0; i < itemsToShow; i++) {
    items.push(
      <CardWrapper key={i}>
        <Card />
      </CardWrapper>
    )
  }
  return items
}

type Props = {
  itemsToShow: Number
}

export const CardLoader = (props: Props) => (
  <Wrapper>
    <CardListWrapper>{renderItems(props.itemsToShow)}</CardListWrapper>
  </Wrapper>
)

export const CardLoaderStaticLength = () => {
  let wrappers = []

  for (let i = 0; i < STATIC_SQUARE_LENGTH; i++) {
    wrappers.push(
      <CardStaticWrapper key={i.toString()}>
        <Card />
      </CardStaticWrapper>
    )
  }

  return (
    <Wrapper>
      <CardListStaticWrapper>{wrappers}</CardListStaticWrapper>
    </Wrapper>
  )
}

export const CardLoaderStaticLengthCircle = () => {
  let wrappers = []

  for (let i = 0; i < STATIC_CIRCLE_LENGTH; i++) {
    wrappers.push(
      <CardStaticCircleWrapper key={i.toString()}>
        <CardCircle>
          <CircleRightFill />
          <CircleBottomFill />
          <CircleBottomSeparator />
          <CircleBottomRightFill />
          <Circle />
        </CardCircle>
      </CardStaticCircleWrapper>
    )
  }

  return (
    <Wrapper>
      <CardListStaticWrapper>{wrappers}</CardListStaticWrapper>
    </Wrapper>
  )
}

CardLoader.defaultProps = {
  itemsToShow: 4
}

// Tour Shop Loader //

const TourWrapper = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 -12px;
  position: relative;

  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  @media only screen and (min-width: 1380px) {
    justify-content: space-between;
  }
`

const TourCardWrapper = styled.div`
  width: 295px;
  height: 397px;
  margin: 0 auto;
`
const TourCard = styled.div`
  background-color: #fff;
  padding: 0 8px;
  position: relative;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden;

  &:only-child {
    margin-top: 0;
  }

  &:before {
    content: '';
    width: 262px;
    height: 100%;
    display: block;
    background-color: #ededed;
  }

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',
      endColorstr='#00ffffff',
      GradientType=1);
  }

  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`

const Padding = styled.div`
  height: 100%;
  width: calc(100% - 24px);
  padding: 0 12px;
`
const renderTourItems = itemsToShow =>
  Array(itemsToShow)
    .fill(null)
    .map((_, index) => (
      <TourCardWrapper key={index}>
        <TourCard>
          <Padding />
        </TourCard>
      </TourCardWrapper>
    ))

export const TourShopLoader = (props: Props) => (
  <CPHomeSectionWrapper
    id="tour_section_0"
    title="TOUR_SHOP_VIENNA"
    subtitle="TOUR_SHOP_VIENNA_SUBTITLE"
  >
    <TourWrapper>{renderTourItems(props.itemsToShow)}</TourWrapper>
  </CPHomeSectionWrapper>
)

TourShopLoader.defaultProps = {
  itemsToShow: 4
}

// Dots Loader //
const DotsLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: ${props => (props.minHeight ? props.minHeight : '30vh')};
`
const SpinnerWrapper = styled.div`
  margin-top: ${props => (props.marginTop ? props.marginTop : '90px')};
  > div {
    width: 18px;
    height: 18px;
    background-color: #e14d6d;
    border-radius: 100%;
    display: inline-block;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`

const SpinnerBounce1 = styled.div`
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
`

const SpinnerBounce2 = styled.div`
  animation: sk-bouncedelay 1.4s infinite 0.16s ease-in-out both;
`

const SpinnerBounce3 = styled.div`
  animation: sk-bouncedelay 1.4s infinite 0.32s ease-in-out both;
`

export const DotsLoader = props => (
  <DotsLoaderWrapper {...props}>
    <SpinnerWrapper {...props}>
      <SpinnerBounce1 />
      <SpinnerBounce2 />
      <SpinnerBounce3 />
    </SpinnerWrapper>
  </DotsLoaderWrapper>
)

const CircleSpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.wWidth ? props.wWidth : '64')}px;
  height: ${props => (props.wHeight ? props.wHeight : '64')}px;
  top: ${props => (props.top ? props.top : '0')}px;
  right: ${props => (props.right ? props.right : '0')}px;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => (props.width ? props.width : '51')}px;
    height: ${props => (props.height ? props.height : '51')}px;
    margin: 6px;
    border: 6px solid ${props => (props.color ? props.color : '#fff')};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => (props.color ? props.color : '#fff')} transparent transparent
      transparent;
  }
  > div:nth-child(1) {
    animation-delay: -0.45s;
  }
  > div:nth-child(2) {
    animation-delay: -0.3s;
  }
  > div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const CircleSpinnerInnerDiv = styled.div``
export const CircleSpinnerLoader = props => (
  <CircleSpinnerWrapper className="lds-ring" {...props}>
    <CircleSpinnerInnerDiv />
    <CircleSpinnerInnerDiv />
    <CircleSpinnerInnerDiv />
    <CircleSpinnerInnerDiv />
  </CircleSpinnerWrapper>
)

export const CardLoaderItem = ({className}) => (
  <CardWrapper className={className}>
    <Card />
  </CardWrapper>
)
