import React, {useEffect, useRef, useState} from 'react'

import {FOOTER_MENU, OTHER_FOOTER_MENU_ITEMS} from 'routes.json'

import withTranslation from 'common/HOCs/withTranslation'
import CPLink from 'components/CPLink'

import {resolveItemLink} from '../../../utils'

import * as S from './styled'

const CP_OTHER_ICON = '/static/images/gray_other_icon.svg'
const FOOTER_HEIGHT = 74

const FooterMenu = props => {
  const {t, i18n} = props
  const wrapperRef = useRef()
  const [menuHeight, setMenuHeight] = useState('')
  const currLang = i18n.language

  const handleOtherClick = () => {
    props.setShowOtherMenuItems(true)
  }

  const handleClose = () => {
    props.setShowOtherMenuItems(false)
    props.setShowMenu()
  }

  useEffect(() => {
    //set height for animation
    if (props.showMenu && !props.showOtherMenuItems) {
      setMenuHeight(`${wrapperRef.current.clientHeight}px`)
    } else if (props.showOtherMenuItems) {
      setMenuHeight(`calc(100% - ${FOOTER_HEIGHT}px)`)
    } else {
      setMenuHeight('auto')
    }
  }, [props])

  useEffect(() => {
    wrapperRef.current.style.height = menuHeight
  }, [menuHeight])

  return (
    <S.MenuWrapper
      ref={wrapperRef}
      showMenu={props.showMenu}
      showOtherMenuItems={props.showOtherMenuItems}
      className={'footer-menu-wrapper'}
    >
      <S.CloseIconWrapper showOtherMenuItems={props.showOtherMenuItems}>
        <S.CloseIcon onClick={handleClose} src="/static/images/close-btn-gray.svg" />
      </S.CloseIconWrapper>
      {FOOTER_MENU.map(item => (
        <CPLink href={resolveItemLink(item, currLang)} key={item.link} openInNewTab={item.newTab}>
          <S.Item>
            <S.Icon src={item.icon} />
            <S.Text>{t(item.text)} </S.Text>
          </S.Item>
        </CPLink>
      ))}
      <S.OtherItemsWrapper showOtherMenuItems={props.showOtherMenuItems}>
        {OTHER_FOOTER_MENU_ITEMS.map(item => (
          <CPLink href={item.link} key={item.link}>
            <S.Item>
              <S.Icon src={item.icon} />
              <S.Text>{t(item.text)} </S.Text>
            </S.Item>
          </CPLink>
        ))}
      </S.OtherItemsWrapper>
      {props.showMenu && !props.showOtherMenuItems && (
        <S.Item onClick={() => handleOtherClick()}>
          <S.Icon src={CP_OTHER_ICON} />
          <S.Text>{t('OTHER')}</S.Text>
        </S.Item>
      )}
    </S.MenuWrapper>
  )
}

export default withTranslation(FooterMenu)
