import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  > svg {
    filter: ${props => (props.isActive ? 'brightness(0.5)' : '')};
  }
`

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`

export const Text = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 130%;
  color: ${props => (props.isActive ? '#14181c' : '#798c9f')};
`
