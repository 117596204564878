import styled from 'styled-components'

import {BASE_CONF} from 'components/common/CPTheme'

export const Wrapper = styled.ul`
  display: none;
  height: inherit;

  *,
  ::after,
  ::before {
    box-sizing: content-box;
  }

  @media screen and ${BASE_CONF.screens.lg} {
    display: flex;
    align-items: center;
    align-self: center;
    margin: 0;
    padding-left: 24px;
  }

  @media screen and ${BASE_CONF.screens.xl} {
    padding-left: 60px;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;
  padding-inline-start: 0;

  a {
    :hover {
      text-decoration: none;
    }
  }
`

export const DropdownPartnersWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const DotsWrapper = styled.div`
  padding: 0 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  width: 20px;
  height: 36px;
  align-items: center;
`

export const Dot = styled.div`
  height: 4px;
  background: #2c3951;
  border-radius: 40px;
`

export const PartnerWrapper = styled.span`
  border: 1px solid #d2d9df;
  border-radius: 44px;
  margin-right: 48px;
`

export const DotsDropdownWrapper = styled.div`
  & .dropdown-icon {
    display: none;
  }
  & .dropdown-content-wrapper {
    border: 1px solid #d2d9df;
    margin-top: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 12px 8px;
    right: 10px;

    &:before,
    &:after {
      display: none;
    }

    & > div {
      padding: 0;

      & > a {
        border: none;
        margin-bottom: 8px;

        :last-child {
          margin-bottom: 0;
        }

        :hover {
          background: #f0f2f4;
          border-radius: 7px;
        }
        & > span {
          font-size: 14px;
          padding: 4px 8px;
          line-height: 130%;
          color: #14181c;
        }
      }
    }
  }
`
