import {useState, useEffect} from 'react'

export default function useResize() {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
      setHeight(window.innerWidth)
    }

    if (window) {
      handleResize()
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (window) window.removeEventListener('resize', handleResize)
    }
  }, [])
  return [width, height]
}
