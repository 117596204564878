import React from 'react'
import styled from 'styled-components'
import withTranslation from 'common/HOCs/withTranslation'

const Wrapper = styled.div`
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  ${props => props.showBorderBottom && 'border-bottom: 1px solid #F2F2F2;'};
`

const Container = styled.div`
  padding: ${props => (props.padding !== undefined ? props.padding : '0 5%')};
  max-width: 930px;
  margin: ${props => (props.margin !== undefined ? props.margin : '35px auto')};
  font-family: 'Nunito Sans', sans-serif;
  background: ${props => (props.background !== undefined ? props.background : 'white')};

  @media only screen and (min-width: 754px) {
    margin-top: ${props =>
      props.wrapperTopMargin !== undefined ? props.wrapperTopMargin : '35px'};
  }

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

const TitleWrapper = styled.h2`
  margin: 0 0 10px 0;
  padding: ${props => (props.padding !== undefined ? props.padding : '0')};
`

const SubTitleWrapper = styled.h4`
  font-weight: 100;
  color: #a1a1a1;
  margin: 0 0 21px 0;
  padding: ${props => (props.padding !== undefined ? props.padding : '0')};
`

type Props = {
  title?: String,
  subtitle?: String,
  wrapperTopMargin?: Number,
  background?: String
}

function CPHomeSectionWrapper(props: Props) {
  const {subtitle, title, t} = props
  const Title = title ? <TitleWrapper padding={props.titlePadding}>{t(title)}</TitleWrapper> : null
  const Subtitle = subtitle ? <SubTitleWrapper>{t(subtitle)}</SubTitleWrapper> : null

  return (
    <Wrapper showBorderBottom={props.showBorderBottom} data-cy="home-section-wrapper">
      <Container {...props} title={t(title)}>
        {Title}
        {Subtitle}
        {props.children}
      </Container>
    </Wrapper>
  )
}

export default withTranslation(CPHomeSectionWrapper)
