import React from 'react'
import styled from 'styled-components'
import {HEADER} from 'consts.json'

const HeaderBarWrapper = styled.div`
  background-color: ${props => props.color};
  height: ${HEADER.BAR_HEIGHT}px;
  top: ${props => (props.showDownloadAppBanner ? `${HEADER.DEFAULT_HEIGHT}px` : 0)};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 1100;
  position: fixed;
`
type Props = {
  appContext: Object,
  color: string,
  children: Object
}
const CPHeaderBar = ({color, children, showDownloadAppBanner}): Props => {
  return (
    <HeaderBarWrapper color={color} showDownloadAppBanner={showDownloadAppBanner}>
      {children}
    </HeaderBarWrapper>
  )
}

export default CPHeaderBar
