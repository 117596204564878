import React from 'react'

import styled from 'styled-components'

import withTranslation from 'common/HOCs/withTranslation'
import CPLink from 'components/CPLink'
import {HEADER, COLOURS as colours} from 'consts.json'

import type {HeaderTypeAppearance} from '../../../CPLayout/types'
import Dropdown, {DropdownContent, DropdownTrigger} from '../../../common/CPDropdown'
import {resolveItemLink} from '../../utils'

const StyledDropdown = styled(Dropdown)`
  height: 100%;
  margin: 0;

  #tour-lang-dropdown-trigger {
    div:nth-child(2) {
      margin: 0;
    }
  }
`

const DropdownSegment = styled.div`
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  border-bottom: rgb(225, 225, 225) 1px solid;

  &:last-of-type {
    border-bottom: rgb(255, 255, 255) 1px solid;
  }
`

const DropdownTriggerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 20px;
  text-decoration: none;
  box-sizing: border-box;
  height: inherit;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: ${props =>
    props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? 'white'
      : props.isActive
      ? colours.activeLink
      : '#253044'};
  opacity: ${props =>
    props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? 'white'
      : props.isActive
      ? 'none'
      : '0.56'};

  :hover {
    color: ${props =>
      props.isActive
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? 'white'
          : colours.activeLink
        : 'grey'};
  }

  &:before {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border: ${props =>
      props.withBox
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? '1px solid white'
          : '1px solid gray'
        : 'none'};
    background-color: ${props =>
      props.withBox && props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
        ? 'rgba(255, 255, 255, 0.1)'
        : 'transparent'};
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 3px;
    background-color: ${props =>
      props.isActive
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? 'white'
          : colours.activeLink
        : null};
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
  }
`

const ListItem = styled.li`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  vertical-align: middle;
  display: table-cell;
  height: inherit;
`

const LinkItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  height: inherit;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: ${props =>
    props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? 'white'
      : props.isActive
      ? colours.activeLink
      : '#253044'};
  :last-child {
    border-bottom: 0;
  }
  &:before {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border: ${props =>
      props.withBox
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? '1px solid white'
          : '1px solid gray'
        : 'none'};
    background-color: ${props =>
      props.withBox && props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
        ? 'rgba(255, 255, 255, 0.1)'
        : 'transparent'};
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 3px;
    background-color: ${props =>
      props.isActive
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? 'white'
          : colours.activeLink
        : null};
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
  }

  :hover {
    span {
      color: ${props =>
        props.isActive
          ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
            ? 'white'
            : colours.activeLink
          : 'grey'};
    }

    &:before {
      border: ${props => (props.withBox ? '1px solid gray' : 'none')};
      background-color: 'transparent';
    }
  }
`

const CPLinkText = styled.span`
  transition: all 0.3s ease-in-out;
  padding: 10px 0px;
  font-size: 13px;

  @media screen and (min-width: 1124px) {
    padding: 10px 0px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 14px;
    padding: 10px 0px;
  }

  ${props =>
    props.separator &&
    `&:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 1px;
    background-color: #898989;
    transform: translateX(-50%) rotate(90deg);
    transition: all 0.3s ease-in-out;
  }`};
`

type Props = {
  key: String,
  item: Object,
  isActive: Boolean,
  headerAppearance?: HeaderTypeAppearance
}

const CPNavigationDropdownItem = (props: Props) => {
  const dropdownRef = React.createRef()

  const {TriggerComponent, i18n} = props
  const currLang = i18n.language

  return (
    <ListItem authButton={props.authButton}>
      <StyledDropdown ref={dropdownRef}>
        <DropdownTrigger id="tour-lang-dropdown-trigger">
          {TriggerComponent ? (
            <TriggerComponent />
          ) : (
            <DropdownTriggerItem>{props.t(props.text)}</DropdownTriggerItem>
          )}
        </DropdownTrigger>
        <DropdownContent right {...props.dropdown}>
          <DropdownSegment>
            {props.items.map(item => (
              <CPLink href={resolveItemLink(item, currLang)} key={item.link}>
                <LinkItem
                  isActive={false}
                  key={item.link}
                  headerAppearance={props.headerAppearance}
                  withBox={props.withBox}
                >
                  <CPLinkText
                    authButton={props.authButton}
                    separator={props.separator}
                    id={`toolbar-nav-item-${props.t(item.text)}`}
                  >
                    {props.t(item.text)}
                  </CPLinkText>
                </LinkItem>
              </CPLink>
            ))}
          </DropdownSegment>
        </DropdownContent>
      </StyledDropdown>
    </ListItem>
  )
}

CPNavigationDropdownItem.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL,
  withBox: false,
  authButton: false,
  separator: false
}

export default withTranslation(CPNavigationDropdownItem)
